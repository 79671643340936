import axios from '../../utils/axiosAuth';
import config from '../../utils/config';

const { URL_API } = config;
const ENDPOINT = 'category_faculties';

export const getFacultiesApi = async () => {
  try {
    const { data } = await axios({
      method: 'get',
      url: `${URL_API}/${ENDPOINT}`,
    });

    return data.result;
  } catch (error) {
    return error.response.data;
  }
};

export const addFacultyApi = async (campus) => {
  try {
    const form = new FormData();

    for (const key in campus) {
      form.append(key, campus[key]);
    }

    const { data } = await axios({
      method: 'post',
      url: `${URL_API}/${ENDPOINT}`,
      data: form,
    });

    return data;
  } catch (error) {
    return error.response.data;
  }
};

export const getFacultyByIdApi = async (id) => {
  try {
    const { data } = await axios({
      method: 'get',
      url: `${URL_API}/${ENDPOINT}/${id}`,
    });

    return data;
  } catch (error) {
    return error.response.data;
  }
};

export const editFacultyApi = async (id, faculty) => {
  const form = new FormData();

  for (const key in faculty) {
    form.append(key, faculty[key]);
  }

  try {
    const { data } = await axios({
      method: 'put',
      url: `${URL_API}/${ENDPOINT}/${id}`,
      data: form,
    });

    return data;
  } catch (error) {
    return error.response.data;
  }
};

export const deleteFacultyApi = async (id) => {
  try {
    const { data } = await axios({
      method: 'delete',
      url: `${URL_API}/${ENDPOINT}/${id}`,
    });

    return data;
  } catch (error) {
    return error.response.data;
  }
};
