import { Autocomplete, Box, Button, TextField } from '@mui/material';
import Page from '../../components/Page';
import SaveIcon from '@mui/icons-material/Save';
import { useNavigate, useParams } from 'react-router-dom';
import { getRolesApi } from '../../services/api/rolesApi';
import { editUserApi, getUserByIdApi } from '../../services/api/usersApi';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { userEditSchema } from '../../schema/user';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
// import ProvincesCitiesForm from '../../components/ProvincesCitiesForm';
import mapOption from '../../utils/mapOption';

export default function EditUser() {
  const textFieldStyle = { mb: 2 };
  const { id } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const queryKey = ['user', id];
  const { data: userData, isSuccess } = useQuery(
    queryKey,
    () => getUserByIdApi(id),
    {
      onSuccess: (data) => {
        setValue('city_id', data.city_id);
        setValue('role_id', data.role_id);
      },
    }
  );
  const roles = useQuery(['roles'], getRolesApi);

  const mutation = useMutation(editUserApi, {
    onSuccess: () => {
      navigate('/users');
      queryClient.invalidateQueries(queryKey);
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: joiResolver(userEditSchema),
    mode: 'onBlur',
  });

  const handleChangeRole = (e, value) => {
    if (value) {
      setValue('role_id', value.id);
    }
  };

  const handleEditUser = (data) => {
    mutation.mutate({ id, data });
  };

  return (
    <>
      <Page
        title="Edit User"
        items={[
          { name: 'Home', url: '/' },
          { name: 'Users', url: '/users' },
        ]}
      >
        {isSuccess & roles.isSuccess && (
          <Box
            component="form"
            sx={{ maxWidth: 500 }}
            onSubmit={handleSubmit(handleEditUser)}
          >
            <TextField
              required
              fullWidth
              label="Email"
              sx={textFieldStyle}
              error={!!errors.email}
              helperText={errors?.email?.message}
              {...register('email', { value: userData.email })}
            />
            <TextField
              required
              fullWidth
              label="Full Name"
              sx={textFieldStyle}
              error={!!errors.full_name}
              helperText={errors?.full_name?.message}
              {...register('full_name', { value: userData.full_name })}
            />
            <Autocomplete
              fullWidth
              // to avoid warning "None of the options match with"
              isOptionEqualToValue={(option, value) =>
                option.label === value.label
              }
              options={roles.data.map(mapOption)}
              sx={textFieldStyle}
              defaultValue={{ id: userData.role.id, label: userData.role.name }}
              onChange={handleChangeRole}
              renderInput={(params) => (
                <TextField required {...params} label="Role" />
              )}
            />

            {/* <ProvincesCitiesForm
              province={{
                id: userData.city.province.id,
                label: userData.city.province.name,
              }}
              city={{ id: userData.city.id, label: userData.city.name }}
              provinceId={userData.city.province.id}
              setValue={setValue}
            /> */}

            <Button type="submit" variant="contained" startIcon={<SaveIcon />}>
              Save
            </Button>
          </Box>
        )}
      </Page>
    </>
  );
}
