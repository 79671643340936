import Joi from 'joi';

const userSchema = Joi.object({
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required(),
  full_name: Joi.string().required(),
  role_id: Joi.number().integer().required(),
  city_id: Joi.number().integer().required(),
  password: Joi.string().min(8).required(),
  confirmPassword: Joi.any()
    .valid(Joi.ref('password'))
    .required()
    .messages({ 'any.only': 'password not match' }),
}).unknown(true);

const userEditSchema = Joi.object({
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required(),
  full_name: Joi.string().required(),
  role_id: Joi.number().integer().required(),
  city_id: Joi.number().integer().required(),
}).unknown(true);

const registerSchema = Joi.object({
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required(),
  full_name: Joi.string().required(),
  city_id: Joi.number().integer().required(),
  password: Joi.string().min(8).required(),
  confirmPassword: Joi.any()
    .valid(Joi.ref('password'))
    .required()
    .messages({ 'any.only': 'password not match' }),
}).unknown(true);

const loginSchema = Joi.object({
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required(),
  password: Joi.string().required(),
});

export { userSchema, userEditSchema, registerSchema, loginSchema };
