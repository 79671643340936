import Joi from 'joi';

const scholarshipSchema = Joi.object({
  name: Joi.string().required(),
  short_name: Joi.string().allow(''),
  // strata: Joi.string().allow(''),
  diploma: Joi.boolean(),
  strata_1: Joi.boolean(),
  strata_2: Joi.boolean(),
  strata_3: Joi.boolean(),
  // jenis_seleksi: Joi.string().allow(''),
  // jenis_tanggungan: Joi.string().allow(''),
  // description: Joi.string().allow(''),
  mulai_pendaftaran: Joi.date().required(),
  akhir_pendaftaran: Joi.date().required(),
  // test_time: Joi.date().required(),
  // pengumuman: Joi.date().required(),
  website: Joi.string().allow(''),
  category_scholarship_id: Joi.number().required(),
  santrihub_campus_id: Joi.array(),
  image: Joi.object().unknown(true).required(),
  // image: Joi.string().allow(''),
  // nominal : Joi.number(),
  // per_time: Joi.string()
});

const scholarshipEditSchema = Joi.object({
  name: Joi.string().required(),
  short_name: Joi.string().allow(''),
  // strata: Joi.string().allow(''),
  // jenis_seleksi: Joi.string().allow(''),
  // jenis_tanggungan: Joi.string().allow(''),
  // description: Joi.string().allow(''),
  mulai_pendaftaran: Joi.date().required(),
  akhir_pendaftaran: Joi.date().required(),
  // pengumuman: Joi.date().required(),
  website: Joi.string().allow(''),
  category_scholarship_id: Joi.number().required(),
  santrihub_campus_id: Joi.array(),
  image: Joi.object().unknown(true),
});

export { scholarshipSchema, scholarshipEditSchema };
