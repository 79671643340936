import { Box, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { pageSelector, setModalOpen } from '../redux/features/pageSlice';

export default function AddDeleteButton() {
  const dispatch = useDispatch();
  const { selection } = useSelector(pageSelector);

  const handleOpen = () => {
    dispatch(setModalOpen(true));
  };

  return (
    <Box>
      <Button
        variant="contained"
        startIcon={<AddIcon />}
        sx={{ mb: 2 }}
        component={Link}
        to="add"
      >
        Add Item
      </Button>
      <Button
        variant="contained"
        color="error"
        startIcon={<DeleteIcon />}
        sx={{
          mb: 2,
          ml: 2,
        }}
        disabled={selection.length === 0 ? true : false}
        onClick={handleOpen}
      >
        Delete selected
      </Button>
    </Box>
  );
}
