import * as React from 'react';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Link as LinkRouter } from 'react-router-dom';
import Link from '@mui/material/Link';
import { Box } from '@mui/material';

export default function Navigation({
  items = [{ name: 'Home', url: '/' }],
  title,
}) {
  return (
    <Box
      sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 1 }}
    >
      <Typography variant="h6">{title}</Typography>
      <Breadcrumbs
        aria-label="breadcrumb"
        sx={{ display: 'flex', alignItems: 'center' }}
      >
        {items.map((item, i) => (
          <Link
            underline="hover"
            color="inherit"
            component={LinkRouter}
            to={item.url}
            key={i}
          >
            {item.name}
          </Link>
        ))}
        <Typography color="text.primary">{title}</Typography>
      </Breadcrumbs>
    </Box>
  );
}
