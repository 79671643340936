import axios from '../../utils/axiosAuth';
import config from '../../utils/config';

const { URL_API } = config;
const ENDPOINT = 'santrihub_roles';

export const getRolesApi = async () => {
  try {
    const { data } = await axios({
      method: 'get',
      url: `${URL_API}/${ENDPOINT}`,
    });

    return data.data;
  } catch (error) {
    return error.response.data;
  }
};

export const addRoleApi = async ({ name }) => {
  try {
    const { data } = await axios({
      method: 'post',
      url: `${URL_API}/${ENDPOINT}`,
      data: { name },
    });

    return data;
  } catch (error) {
    return error.response.data;
  }
};

export const getRoleByIdApi = async (id) => {
  try {
    const { data } = await axios({
      method: 'get',
      url: `${URL_API}/${ENDPOINT}/${id}`,
    });

    return data.data;
  } catch (error) {
    return error.response.data;
  }
};

export const editRoleApi = async ({ id, data: dataRole }) => {
  try {
    const { data } = await axios({
      method: 'put',
      url: `${URL_API}/${ENDPOINT}/${id}`,
      data: dataRole,
    });

    return data;
  } catch (error) {
    return error.response.data;
  }
};

export const deleteRoleApi = async (id) => {
  try {
    const { data } = await axios({
      method: 'delete',
      url: `${URL_API}/${ENDPOINT}/${id}`,
    });

    return data;
  } catch (error) {
    return error.response.data;
  }
};
