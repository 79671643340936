import axios from '../../utils/axiosAuth';
import config from '../../utils/config';

const { URL_API } = config;
const ENDPOINT = 'category_campuses';

export const getCategoryCampusesApi = async () => {
  try {
    const { data } = await axios({
      method: 'get',
      url: `${URL_API}/${ENDPOINT}`,
    });

    return data.result;
  } catch (error) {
    return error.response.data;
  }
};

export const addCategoryCampusApi = async ({
  name,
  description,
  short_name,
}) => {
  try {
    const { data } = await axios({
      method: 'post',
      url: `${URL_API}/${ENDPOINT}`,
      data: { name, description, short_name },
    });

    return data;
  } catch (error) {
    return error.response.data;
  }
};
