import { IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';

import { useDispatch } from 'react-redux';
import { setItemId, setModalOpen } from '../redux/features/pageSlice';

export default function ActionField({ id }) {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const handleDelete = () => {
    dispatch(setModalOpen(true));
    dispatch(setItemId(id));
  };

  return (
    <>
      <IconButton sx={{ mr: 1 }} onClick={() => navigate(`${id}/edit`)}>
        <EditIcon color="info" />
      </IconButton>
      <IconButton onClick={handleDelete}>
        <DeleteIcon color="error" />
      </IconButton>
    </>
  );
}
