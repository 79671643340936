import { Box, Button, TextField } from '@mui/material';
import Page from '../../components/Page';
import SaveIcon from '@mui/icons-material/Save';
import { useNavigate, useParams } from 'react-router-dom';
import { editRoleApi, getRoleByIdApi } from '../../services/api/rolesApi';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import roleSchema from '../../schema/role';
import { useMutation, useQuery } from '@tanstack/react-query';

export default function EditRole() {
  const { id } = useParams();
  const navigate = useNavigate();

  const queryKey = ['role', id];
  const { data, isSuccess } = useQuery(queryKey, () => getRoleByIdApi(id));

  const mutation = useMutation(editRoleApi, {
    onSuccess: () => navigate('/roles'),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: joiResolver(roleSchema),
  });

  const handleEditRole = async (data) => {
    mutation.mutate({ id, data });
  };

  return (
    <>
      <Page
        title="Edit Role"
        items={[
          { name: 'Home', url: '/' },
          { name: 'Roles', url: '/roles' },
        ]}
      >
        {isSuccess && (
          <Box
            component="form"
            sx={{ maxWidth: 500 }}
            onSubmit={handleSubmit(handleEditRole)}
          >
            <TextField
              required
              fullWidth
              label="Name"
              sx={{ mb: 2 }}
              error={!!errors.name}
              helperText={errors?.name?.message}
              {...register('name', { value: data.name })}
            />
            <Button type="submit" variant="contained" startIcon={<SaveIcon />}>
              Save
            </Button>
          </Box>
        )}
      </Page>
    </>
  );
}
