import { Box, Button, TextField } from '@mui/material';
import Page from '../../components/Page';
import SaveIcon from '@mui/icons-material/Save';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import roleSchema from '../../schema/role';
import { addRoleApi } from '../../services/api/rolesApi';
import { useMutation } from '@tanstack/react-query';

export default function AddRole() {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: joiResolver(roleSchema),
  });

  const mutation = useMutation(addRoleApi, {
    onSuccess: () => navigate('/roles'),
  });

  const handleAddRole = (data) => {
    mutation.mutate(data);
  };

  return (
    <>
      <Page
        title="Add Role"
        items={[
          { name: 'Home', url: '/' },
          { name: 'Roles', url: '/roles' },
        ]}
      >
        <Box
          component="form"
          sx={{ maxWidth: 500 }}
          onSubmit={handleSubmit(handleAddRole)}
        >
          <TextField
            required
            fullWidth
            label="Name"
            sx={{ mb: 2 }}
            error={!!errors.name}
            helperText={errors?.name?.message}
            {...register('name')}
          />
          <Button type="submit" variant="contained" startIcon={<SaveIcon />}>
            Save
          </Button>
        </Box>
      </Page>
    </>
  );
}
