import Joi from 'joi';

const organizationSchema = Joi.object({
  name: Joi.string().required(),
  short_name: Joi.string().allow(''),
  category: Joi.string().allow(''),
  ketua: Joi.string().allow(''),
  alamat: Joi.string().allow(''),
  description: Joi.string().allow(''),
  email: Joi.string().allow(''),
  website: Joi.string().allow(''),
  ig_link: Joi.string().allow(''),
  no_contact: Joi.string().allow(''),
  santrihub_campus_id: Joi.number().integer().required(),
  image: Joi.object().unknown(true).required(),
});

const organizationEditSchema = Joi.object({
  name: Joi.string().required(),
  short_name: Joi.string().allow(''),
  category: Joi.string().allow(''),
  ketua: Joi.string().allow(''),
  alamat: Joi.string().allow(''),
  description: Joi.string().allow(''),
  email: Joi.string().allow(''),
  website: Joi.string().allow(''),
  ig_link: Joi.string().allow(''),
  no_contact: Joi.string().allow(''),
  santrihub_campus_id: Joi.number().integer().required(),
  image: Joi.object().unknown(true),
});

export { organizationSchema, organizationEditSchema };
