import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  sidebarOpen: true,
};

export const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    setSidebarOpen: (state, action) => {
      state.sidebarOpen = action.payload;
    },
  },
});

export const { setSidebarOpen } = sidebarSlice.actions;

export const sidebarSelector = (state) => state.sidebar;

export default sidebarSlice.reducer;
