import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  InputBase,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import Page from '../../components/Page';
import SaveIcon from '@mui/icons-material/Save';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { pesantrenSchema } from '../../schema/pesantren';
import { addPesantrenApi } from '../../services/api/pesantrensApi';
import { getCampusesApi } from '../../services/api/campusesApi';
import { grey } from '@mui/material/colors';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';

export default function AddPesantren() {
  const navigate = useNavigate();

  const textFieldStyle = { mb: 2 };

  const [campuses, setCampuses] = useState([]);
  const [logo, setLogo] = useState(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: joiResolver(pesantrenSchema),
    mode: 'onBlur',
  });

  const handleLogoInput = (e) => {
    setLogo(e.target.files[0]);
    setValue('image', e.target.files[0]);
  };

  const handleChangeCampus = (e, value) => {
    if (value) {
      setValue('santrihub_campus_id', value.id);
    }
  };

  const handleAddPesantren = async (data) => {
    const response = await addPesantrenApi(data);
    if (response.success) navigate('/pesantren');
  };

  const getData = useCallback(async () => {
    const responseCampuses = await getCampusesApi();
    const campusesMapped = responseCampuses.map((campus) => ({
      label: campus.name,
      id: campus.id,
    }));
    setCampuses(campusesMapped);
  }, []);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <>
      <Page
        title="Add Pesantren"
        items={[
          { name: 'Home', url: '/' },
          { name: 'Pesantren', url: '/pesantren' },
        ]}
      >
        <Box
          component="form"
          sx={{ maxWidth: 500 }}
          onSubmit={handleSubmit(handleAddPesantren)}
        >
          <TextField
            required
            fullWidth
            label="Name"
            sx={textFieldStyle}
            error={!!errors.name}
            helperText={errors?.name?.message}
            {...register('name')}
          />
          <TextField
            fullWidth
            label="Short name"
            sx={textFieldStyle}
            error={!!errors.short_name}
            helperText={errors?.short_name?.message}
            {...register('short_name')}
          />
          <FormControl sx={textFieldStyle} error={!!errors.gender}>
            <FormLabel id="demo-row-radio-buttons-group-label">
              Gender
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
            >
              <FormControlLabel
                value="Laki-laki"
                control={<Radio />}
                label="Laki-laki"
                {...register('gender')}
              />
              <FormControlLabel
                value="Perempuan"
                control={<Radio />}
                label="Perempuan"
                {...register('gender')}
              />
              <FormControlLabel
                value="Campur"
                control={<Radio />}
                label="Campur"
                {...register('gender')}
              />
            </RadioGroup>
            {!!errors.gender && (
              <FormHelperText>Please select a gender</FormHelperText>
            )}
          </FormControl>
          <TextField
            fullWidth
            label="Pengasuh"
            sx={textFieldStyle}
            error={!!errors.pengasuh}
            helperText={errors?.pengasuh?.message}
            {...register('pengasuh')}
          />
          <TextField
            fullWidth
            label="Affiliate"
            sx={textFieldStyle}
            error={!!errors.afiliate}
            helperText={errors?.afiliate?.message}
            {...register('afiliate')}
          />
          <TextField
            fullWidth
            label="Description"
            sx={textFieldStyle}
            error={!!errors.description}
            helperText={errors?.description?.message}
            {...register('description')}
          />
          <TextField
            fullWidth
            label="Email"
            sx={textFieldStyle}
            error={!!errors.email}
            helperText={errors?.email?.message}
            {...register('email')}
          />
          <TextField
            fullWidth
            label="Website"
            sx={textFieldStyle}
            error={!!errors.website}
            helperText={errors?.website?.message}
            {...register('website')}
          />
          <TextField
            fullWidth
            label="Instagram link"
            sx={textFieldStyle}
            error={!!errors.ig_link}
            helperText={errors?.ig_link?.message}
            {...register('ig_link')}
          />
          <TextField
            fullWidth
            label="Contact"
            sx={textFieldStyle}
            error={!!errors.no_contact}
            helperText={errors?.no_contact?.message}
            {...register('no_contact')}
          />
          <Autocomplete
            fullWidth
            options={campuses}
            sx={textFieldStyle}
            onChange={handleChangeCampus}
            renderInput={(params) => (
              <TextField required label="Campus" {...params} />
            )}
          />
          <label htmlFor="logo">
            <Box sx={{ display: 'flex' }}>
              <Typography
                sx={{
                  width: '50%',
                  padding: 1,
                  paddingLeft: 2,
                  border: `solid ${grey[400]}`,
                  borderWidth: '0.5px',
                  mr: 2,
                }}
              >
                Logo
              </Typography>
              <AddPhotoAlternateIcon fontSize="large" />
            </Box>
          </label>
          <InputBase
            fullWidth
            required
            id="logo"
            type="file"
            accept="image/png, image/jpeg, image/webp, image/gif"
            onChange={handleLogoInput}
            sx={{ ...textFieldStyle, mt: 1 }}
          />
          {logo && (
            <img
              src={URL.createObjectURL(logo)}
              alt=""
              style={{ width: '100%', marginBottom: 15 }}
              onLoad={(e) => URL.revokeObjectURL(e.target.src)}
            />
          )}
          <Button type="submit" variant="contained" startIcon={<SaveIcon />}>
            Save
          </Button>
        </Box>
      </Page>
    </>
  );
}
