import { Box, Button, Modal, Typography } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSelector, useDispatch } from 'react-redux';
import {
  pageSelector,
  setItemId,
  setModalOpen,
} from '../redux/features/pageSlice';

export default function ModalDelete({ handleDelete, queryKey }) {
  const dispatch = useDispatch();
  const { modalOpen, itemId, selection } = useSelector(pageSelector);
  const queryClient = useQueryClient();

  const handleClose = () => {
    dispatch(setModalOpen(false));
    dispatch(setItemId(null));
  };

  const mutation = useMutation(handleDelete, {
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries(queryKey);
          window.location.reload(true);
    },
  });

  const handleClickDelete = () => {
    if (itemId) {
      mutation.mutate(itemId);
    } else {
      selection.forEach((id) => mutation.mutate(id));
    }
    handleClose();
  };

  return (
    <Modal open={modalOpen} onClose={handleClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 300,
          bgcolor: 'background.paper',
          p: 2,
        }}
      >
        <Typography sx={{ mb: 1 }}>Are you sure to delete it ?</Typography>
        <Box sx={{ textAlign: 'center' }}>
          <Button
            variant="outlined"
            color="error"
            size="small"
            sx={{ mr: 2 }}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="error"
            size="small"
            onClick={handleClickDelete}
          >
            Delete
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
