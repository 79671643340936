import {
  Autocomplete,
  Box,
  Button,
  InputBase,
  TextField,
  Typography,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Page from '../../components/Page';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { grey } from '@mui/material/colors';
import { campusEditSchema } from '../../schema/campus';
import {
  editCampusApi,
  getCampusByIdApi,
} from '../../services/api/campusesApi';
import { getCategoryCampusesApi } from '../../services/api/categoryCampusesApi';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import ProvincesCitiesForm from '../../components/ProvincesCitiesForm';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import mapOption from '../../utils/mapOption';

export default function EditCampus() {
  const { id } = useParams();
  const textFieldStyle = { mb: 2 };
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const queryKey = ['campus', id];

  const mutation = useMutation(editCampusApi, {
    onSuccess: () => {
      navigate('/campuses');
      queryClient.invalidateQueries(queryKey);
    },
  });

  const { data: campusData, isSuccess } = useQuery(
    queryKey,
    () => getCampusByIdApi(id),
    {
      onSuccess: (data) => {
        setValue('category_campus_id', data.category_campus_id);
        setValue('province_id', data.province_id);
        setValue('city_id', data.city_id);
        setLogo(data.logo);
      },
    }
  );

  const categoryCampuses = useQuery(
    ['category-campuses'],
    getCategoryCampusesApi
  );

  const [logo, setLogo] = useState(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: joiResolver(campusEditSchema),
    mode: 'onBlur',
  });

  const handleChangeCategory = (e, value) => {
    if (value) {
      setValue('category_campus_id', value.id);
    }
  };

  const handleLogoInput = (e) => {
    setLogo(e.target.files[0]);
    setValue('image', e.target.files[0]);
  };

  const handleEditCampus = async (data) => {
    console.log({data}, 'data');
    mutation.mutate({ id, data });
  };

  return (
    <>
      <Page
        title="Edit Campus"
        items={[
          { name: 'Home', url: '/' },
          { name: 'Campuses', url: '/campuses' },
        ]}
      >
        {isSuccess && categoryCampuses.isSuccess && (
          <Box
            component="form"
            sx={{ maxWidth: 500 }}
            onSubmit={handleSubmit(handleEditCampus)}
          >
            <TextField
              required
              fullWidth
              label="Name"
              sx={textFieldStyle}
              error={!!errors.name}
              helperText={errors?.name?.message}
              {...register('name', { value: campusData.name })}
            />
            <TextField
              fullWidth
              label="Short name"
              sx={textFieldStyle}
              error={!!errors.short_name}
              helperText={errors?.short_name?.message}
              {...register('short_name', { value: campusData.short_name })}
            />
            <TextField
              fullWidth
              label="Telegram"
              sx={textFieldStyle}
              error={!!errors.telegram}
              helperText={errors?.telegram?.message}
              {...register('telegram', { value: campusData.telegram })}
            />
            <TextField
              fullWidth
              label="Website"
              sx={textFieldStyle}
              error={!!errors.website}
              helperText={errors?.website?.message}
              {...register('website', { value: campusData.website })}
            />
            <TextField
              fullWidth
              label="Description"
              sx={textFieldStyle}
              error={!!errors.description}
              helperText={errors?.description?.message}
              {...register('description', { value: campusData.description })}
            />
            <Autocomplete
              fullWidth
              options={categoryCampuses.data.map(mapOption)}
              sx={textFieldStyle}
              value={{
                id: campusData.category_campus_id,
                label: campusData.category_campus_name,
              }}
              onChange={handleChangeCategory}
              // to avoid warning "None of the options match with"
              isOptionEqualToValue={(option, value) =>
                option.label === value.label
              }
              renderInput={(params) => (
                <TextField required label="Category campus" {...params} />
              )}
            />

            <ProvincesCitiesForm
              setValue={setValue}
              province={{
                id: campusData.province_id,
                label: campusData.province_name,
              }}
              city={{ id: campusData.city_id, label: campusData.city_name }}
              provinceId={campusData.province_id}
            />

            <label htmlFor="logo">
              <Box sx={{ display: 'flex' }}>
                <Typography
                  sx={{
                    width: '50%',
                    padding: 1,
                    paddingLeft: 2,
                    border: `solid ${grey[400]}`,
                    borderWidth: '0.5px',
                    mr: 2,
                  }}
                >
                  Logo
                </Typography>
                <AddPhotoAlternateIcon fontSize="large" />
              </Box>
            </label>
            <InputBase
              fullWidth
              id="logo"
              type="file"
              accept="image/png, image/jpeg, image/webp, image/gif"
              onChange={handleLogoInput}
              sx={{ ...textFieldStyle, mt: 1 }}
            />
            {typeof logo === 'string' && (
              <img
                src={logo}
                alt=""
                style={{ width: '100%', marginBottom: 15 }}
              />
            )}
            {logo && typeof logo !== 'string' && (
              <img
                src={URL.createObjectURL(logo)}
                alt=""
                style={{ width: '100%', marginBottom: 15 }}
                onLoad={(e) => URL.revokeObjectURL(e.target.src)}
              />
            )}
            <Button type="submit" variant="contained" startIcon={<SaveIcon />}>
              Save
            </Button>
          </Box>
        )}
      </Page>
    </>
  );
}
