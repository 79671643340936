import { Box } from '@mui/material';
import Page from '../../components/Page';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import AddDeleteButton from '../../components/AddDeleteButton';
import ModalDelete from '../../components/ModalDelete';
import ActionField from '../../components/ActionField';
import { setSelection } from '../../redux/features/pageSlice';
import { useQuery } from '@tanstack/react-query';
import { deleteRoleApi, getRolesApi } from '../../services/api/rolesApi';

export default function Roles() {
  const dispatch = useDispatch();
  const queryKey = ['roles'];
  const { data, isLoading } = useQuery(queryKey, getRolesApi);

  const [pageSize, setPageSize] = useState(5);

  const handleSelect = (selection) => {
    dispatch(setSelection(selection));
  };

  const columns = [
    { field: 'id', headerName: 'ID', width: 90 },
    {
      field: 'name',
      headerName: 'Name',
      width: 150,
    },
    {
      field: 'createdAt',
      headerName: 'Created At',
      width: 150,
      valueFormatter: (params) => {
        return new Date(params.value).toDateString();
      },
    },
    {
      field: 'action',
      headerName: 'Action',
      sortable: false,
      width: 160,
      renderCell: (params) => {
        return <ActionField id={params.id} />;
      },
    },
  ];

  return (
    <>
      <Page title="Roles">
        <AddDeleteButton />
        <Box sx={{ height: 430, width: '100%' }}>
          <DataGrid
            components={{ Toolbar: GridToolbar }}
            rows={isLoading ? [] : data}
            columns={columns}
            pageSize={pageSize}
            loading={isLoading}
            rowsPerPageOptions={[5]}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            checkboxSelection
            disableSelectionOnClick
            onSelectionModelChange={handleSelect}
          />
        </Box>
        <ModalDelete handleDelete={deleteRoleApi} queryKey={queryKey} />
      </Page>
    </>
  );
}
