import { Autocomplete, TextField } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { getProvinceById, getProvincesApi } from '../services/api/provincesApi';
import mapOption from '../utils/mapOption';

export default function ProvincesCitiesForm({
  city = null,
  province = null,
  provinceId = null,
  setValue,
}) {
  const textFieldStyle = { mb: 2 };

  const { data: provinces, isSuccess } = useQuery(
    ['provinces'],
    getProvincesApi
  );
  useQuery(['cities', provinceId], () => getProvinceById(provinceId), {
    onSuccess: (data) => setCities(data?.cities?.map(mapOption) || []),
  });

  const [cities, setCities] = useState([]);
  const [cityState, setCity] = useState(city);
  const [provinceState, setProvince] = useState(province);

  const handleSelectProvince = async (e, value) => {
    setProvince(value);
    if (value) {
      setValue('province_id', value.id);
      const response = await getProvinceById(value.id);
      const citiesMapped = response.cities.map(mapOption);
      setCities(citiesMapped);
      setCity(null);
    } else {
      setCities([]);
      setCity(null);
    }
  };

  const handleChangeCity = (e, value) => {
    if (value) {
      setCity(value);
      setValue('city_id', value.id);
    }
  };

  return (
    isSuccess && (
      <>
        <Autocomplete
          fullWidth
          isOptionEqualToValue={(option, value) => option.label === value.label}
          options={provinces.map(mapOption)}
          value={provinceState}
          onChange={handleSelectProvince}
          sx={textFieldStyle}
          renderInput={(params) => (
            <TextField required {...params} label="Province" />
          )}
        />
        <Autocomplete
          fullWidth
          isOptionEqualToValue={(option, value) => option.label === value.label}
          value={cityState}
          onChange={handleChangeCity}
          options={cities}
          sx={textFieldStyle}
          renderInput={(params) => (
            <TextField required {...params} label="City" />
          )}
        />
      </>
    )
  );
}
