import axios from '../../utils/axiosAuth';
import config from '../../utils/config';

const { URL_API } = config;
const ENDPOINT = 'santrihub_pesantren';

export const getPesantrensApi = async () => {
  try {
    const { data } = await axios({
      method: 'get',
      url: `${URL_API}/${ENDPOINT}`,
    });

    return data;
  } catch (error) {
    return error.response.data;
  }
};

export const addPesantrenApi = async (pesantrenData) => {
  try {
    const form = new FormData();

    for (const key in pesantrenData) {
      form.append(key, pesantrenData[key]);
    }

    const { data } = await axios({
      method: 'post',
      url: `${URL_API}/${ENDPOINT}`,
      data: form,
    });

    return data;
  } catch (error) {
    return error.response.data;
  }
};

export const getPesantrenByIdApi = async (id) => {
  try {
    const { data } = await axios({
      method: 'get',
      url: `${URL_API}/${ENDPOINT}/${id}`,
    });

    return data;
  } catch (error) {
    return error.response.data;
  }
};

export const editPesantrenApi = async (id, pesantrenData) => {
  try {
    const form = new FormData();

    for (const key in pesantrenData) {
      form.append(key, pesantrenData[key]);
    }

    const { data } = await axios({
      method: 'put',
      url: `${URL_API}/${ENDPOINT}/${id}`,
      data: form,
    });

    return data;
  } catch (error) {
    return error.response.data;
  }
};

export const deletePesantrenApi = async (id) => {
  try {
    const { data } = await axios({
      method: 'delete',
      url: `${URL_API}/${ENDPOINT}/${id}`,
    });

    return data;
  } catch (error) {
    return error.response.data;
  }
};
