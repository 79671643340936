import { Typography } from '@mui/material';
import Page from '../components/Page';

export default function Home() {
  return (
    <>
      <Page title="Dashboard">
        <Typography variant="h2" textAlign="center">
          Dashboard
        </Typography>
      </Page>
    </>
  );
}
