import {
  Autocomplete,
  Box,
  Button,
  InputBase,
  TextField,
  Typography,
} from '@mui/material';
import Page from '../../components/Page';
import SaveIcon from '@mui/icons-material/Save';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { campusSchema } from '../../schema/campus';
import { addCampusApi } from '../../services/api/campusesApi';
import { getCategoryCampusesApi } from '../../services/api/categoryCampusesApi';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { grey } from '@mui/material/colors';
import ProvincesCitiesForm from '../../components/ProvincesCitiesForm';
import { useMutation, useQuery } from '@tanstack/react-query';
import mapOption from '../../utils/mapOption';

export default function AddCampus() {
  const textFieldStyle = { mb: 2 };
  const navigate = useNavigate();

  const mutation = useMutation(addCampusApi, {
    onSuccess: () => navigate('/campuses'),
  });

  const categoryCampuses = useQuery(
    ['category-campuses'],
    getCategoryCampusesApi
  );

  const [logo, setLogo] = useState(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: joiResolver(campusSchema),
    mode: 'onBlur',
  });

  const handleChangeCategory = (e, value) => {
    if (value) {
      setValue('category_campus_id', value.id);
    }
  };

  const handleLogoInput = (e) => {
    setLogo(e.target.files[0]);
    setValue('image', e.target.files[0]);
  };

  const handleAddCampus = async (data) => {
    console.log({data, logo}, 'data');
    mutation.mutate(data);
  };

  return (
    <>
      <Page
        title="Add Campus"
        items={[
          { name: 'Home', url: '/' },
          { name: 'Campuses', url: '/campuses' },
        ]}
      >
      {categoryCampuses.isSuccess && (
        <Box
          component="form"
          sx={{ maxWidth: 500 }}
          onSubmit={handleSubmit(handleAddCampus)}
        >
          <TextField
            required
            fullWidth
            label="Name"
            sx={textFieldStyle}
            error={!!errors.name}
            helperText={errors?.name?.message}
            {...register('name')}
          />
          <TextField
            fullWidth
            label="Short name"
            sx={textFieldStyle}
            error={!!errors.short_name}
            helperText={errors?.short_name?.message}
            {...register('short_name')}
          />
          <TextField
            fullWidth
            label="Telegram"
            sx={textFieldStyle}
            error={!!errors.telegram}
            helperText={errors?.telegram?.message}
            {...register('telegram')}
          />
          <TextField
            fullWidth
            label="Website"
            sx={textFieldStyle}
            error={!!errors.website}
            helperText={errors?.website?.message}
            {...register('website')}
          />
          <TextField
            fullWidth
            label="Description"
            sx={textFieldStyle}
            error={!!errors.description}
            helperText={errors?.description?.message}
            {...register('description')}
          />
          <Autocomplete
            fullWidth
            options={categoryCampuses.data.map(mapOption)}
            sx={textFieldStyle}
            onChange={handleChangeCategory}
            renderInput={(params) => (
              <TextField required label="Category campus" {...params} />
            )}
          />
          <ProvincesCitiesForm setValue={setValue} />
          <label htmlFor="logo">
            <Box sx={{ display: 'flex' }}>
              <Typography
                sx={{
                  width: '50%',
                  padding: 1,
                  paddingLeft: 2,
                  border: `solid ${grey[400]}`,
                  borderWidth: '0.5px',
                  mr: 2,
                }}
              >
                Logo
              </Typography>
              <AddPhotoAlternateIcon fontSize="large" />
            </Box>
          </label> 
         
         <InputBase
            fullWidth
            required
            id="logo"
            type="file"
            accept="image/png, image/jpeg, image/webp, image/gif"
            onChange={handleLogoInput}
            sx={{ ...textFieldStyle, mt: 1 }}
          />
          {logo && (
            <img
              src={URL.createObjectURL(logo)}
              alt=""
              style={{ width: '100%', marginBottom: 15 }}
              onLoad={(e) => URL.revokeObjectURL(e.target.src)}
            />
          )}
          {/* <TextField
            required
            fullWidth
            label="Logo"
            sx={textFieldStyle}
            error={!!errors.image}
            helperText={errors?.image?.message}
            {...register("image")}
          /> */}
          <Button type="submit" variant="contained" startIcon={<SaveIcon />}>
            Save
          </Button>
        </Box>
      )}
      </Page>
    </>
  );
}
