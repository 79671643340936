import { Box, Button, TextField } from '@mui/material';
import Page from '../../components/Page';
import SaveIcon from '@mui/icons-material/Save';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import categoryScholarshipSchema from '../../schema/categoryScholarship';
// import { addCategoryCampusApi } from '../../services/api/categoryCampusesApi';
import { addCategoryScholarshipApi } from '../../services/api/categoryScholarshipsApi';

export default function AddCategoryScholarship() {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: joiResolver(categoryScholarshipSchema),
  });

  const handleAddCategoryScholarship = async (data) => {
    // await addCategoryCampusApi(data);
    await addCategoryScholarshipApi(data)
    navigate('/category-scholarships');
  };

  return (
    <>
      <Page
        title="Add CategoryScholarship"
        items={[
          { name: 'Home', url: '/' },
          { name: 'CategoryScholarships', url: '/categoryScholarships' },
        ]}
      >
        <Box
          component="form"
          sx={{ maxWidth: 500 }}
          onSubmit={handleSubmit(handleAddCategoryScholarship)}
        >
          <TextField
            required
            fullWidth
            label="Name"
            sx={{ mb: 2 }}
            error={!!errors.name}
            helperText={errors?.name?.message}
            {...register('name')}
          />
          <Button type="submit" variant="contained" startIcon={<SaveIcon />}>
            Save
          </Button>
        </Box>
      </Page>
    </>
  );
}
