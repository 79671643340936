import { configureStore } from '@reduxjs/toolkit';
import pageReducer from './features/pageSlice';
import scholarshipReducer from './features/scholarshipSlice';
import pesantrenReducer from './features/pesantrenSlice';
import sidebarReducer from './features/sidebarSlice';

export default configureStore({
  reducer: {
    page: pageReducer,
    sidebar: sidebarReducer,
    scholarship: scholarshipReducer,
    pesantren: pesantrenReducer,
  },
});
