import Joi from 'joi';

const jalurMasukSchema = Joi.object({
  name: Joi.string().required(),
  short_name: Joi.string().allow(''),
  strata: Joi.string().allow(''),
  jenis_seleksi: Joi.string().allow(''),
  short_description: Joi.string().allow(''),
  tahapan: Joi.array(),
  mulai_pendaftaran: Joi.date().required(),
  akhir_pendaftaran: Joi.date().required(),
  pengumuman: Joi.date().required(),
  website: Joi.string().allow(''),
  biaya: Joi.number().required(),
  
  // santrihub_campus_id: Joi.number().integer().required(),
}).unknown(true);

export { jalurMasukSchema };
