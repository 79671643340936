import { Box } from '@mui/material';
import Page from '../../components/Page';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import AddDeleteButton from '../../components/AddDeleteButton';
import ModalDelete from '../../components/ModalDelete';
import { setSelection } from '../../redux/features/pageSlice';
import { useQuery } from '@tanstack/react-query';
import { getCategoryCampusesApi } from '../../services/api/categoryCampusesApi';

export default function CategoryCampuses() {
  const dispatch = useDispatch();

  const queryKey = ['category-campuses'];
  const { data, isLoading } = useQuery(queryKey, getCategoryCampusesApi);

  const [pageSize, setPageSize] = useState(10);

  const handleSelect = (selection) => {
    dispatch(setSelection(selection));
  };

  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    {
      field: 'name',
      headerName: 'Name',
      width: 150,
    },
    {
      field: 'short_name',
      headerName: 'Short name',
      width: 120,
    },
    {
      field: 'description',
      headerName: 'Description',
      width: 170,
      valueFormatter: (params) => {
        return params.value.name;
      },
    },
    {
      field: 'createdAt',
      type: 'dateTime',
      headerName: 'Created At',
      width: 150,
      valueFormatter: (params) => {
        return new Date(params.value).toDateString();
      },
    },
  ];

  return (
    <>
      <Page title="Category campuses">
        <AddDeleteButton />
        <Box sx={{ height: 430, width: '100%' }}>
          <DataGrid
            components={{ Toolbar: GridToolbar }}
            rows={isLoading ? [] : data}
            loading={isLoading}
            columns={columns}
            pageSize={pageSize}
            rowsPerPageOptions={[10, 20, 30, 40, 50]}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            checkboxSelection
            disableSelectionOnClick
            onSelectionModelChange={handleSelect}
          />
        </Box>
        <ModalDelete />
      </Page>
    </>
  );
}
