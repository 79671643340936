import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { sidebarSelector } from '../redux/features/sidebarSlice';

export default function MenuListItem({ icon, text, to, children, ...props }) {
  const navigate = useNavigate();
  const { sidebarOpen } = useSelector(sidebarSelector);

  return (
    <ListItem
      disablePadding
      sx={{ display: 'block' }}
      onClick={() => navigate(to)}
      {...props}
    >
      <ListItemButton
        sx={{
          minHeight: 48,
          justifyContent: sidebarOpen ? 'initial' : 'center',
          px: 2.5,
        }}
        selected={window.location.pathname === to}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: sidebarOpen ? 2 : 'auto',
            justifyContent: 'center',
          }}
        >
          {icon}
        </ListItemIcon>
        <ListItemText primary={text} sx={{ opacity: sidebarOpen ? 1 : 0 }} />
        {children}
      </ListItemButton>
    </ListItem>
  );
}
