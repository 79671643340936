import { Box, Link } from '@mui/material';
import Page from '../../components/Page';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import AddDeleteButton from '../../components/AddDeleteButton';
import ModalDelete from '../../components/ModalDelete';
import ActionField from '../../components/ActionField';
import { setSelection } from '../../redux/features/pageSlice';
import {
  // deleteScholarship,
  getScholarships,
  scholarshipSelector,
} from '../../redux/features/scholarshipSlice';
import DetailCard from '../../components/DetailCard';
import { getScholarshipByIdApi,deleteScholarshipApi } from '../../services/api/scholarshipsApi';

export default function Scholarships() {
  const dispatch = useDispatch();
  const scholarships = useSelector(scholarshipSelector.selectAll);

  const [pageSize, setPageSize] = useState(10);
  const [scholarship, setScholarship] = useState(null);

  const handleSelect = (selection) => {
    dispatch(setSelection(selection));
  };

  const handleCellClick = async (params) => {
    const response = await getScholarshipByIdApi(params.row.id);
    setScholarship(response.result);
  };

  useEffect(() => {
    dispatch(getScholarships());
  }, [dispatch]);

  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    {
      field: 'name',
      headerName: 'Name',
      width: 150,
      editable: false,
    },
    {
      field: 'short_name',
      headerName: 'Short name',
      width: 120,
      editable: false,
    },
    {
      field: 'strata',
      headerName: 'Strata',
      width: 100,
      editable: false,
    },
    {
      field: 'jenis_seleksi',
      headerName: 'Jenis seleksi',
      width: 120,
      editable: false,
    },
    {
      field: 'jenis_tanggungan',
      headerName: 'Jenis tanggungan',
      width: 120,
      editable: false,
    },
    {
      field: 'description',
      headerName: 'Description',
      width: 150,
      editable: false,
    },
    {
      field: 'mulai_pendaftaran',
      headerName: 'Mulai pendaftaran',
      type: 'dateTime',
      width: 150,
      editable: false,
      valueFormatter: (params) => {
        return new Date(params.value).toDateString();
      },
    },
    {
      field: 'akhir_pendaftaran',
      headerName: 'Akhir pendaftaran',
      type: 'dateTime',
      width: 150,
      editable: false,
      valueFormatter: (params) => {
        return new Date(params.value).toDateString();
      },
    },
    {
      field: 'pengumuman',
      headerName: 'Pengumuman',
      type: 'dateTime',
      width: 150,
      editable: false,
      valueFormatter: (params) => {
        return new Date(params.value).toDateString();
      },
    },
    {
      field: 'website',
      headerName: 'Website',
      width: 130,
      editable: false,
      renderCell: (params) => {
        return (
          <Link href={params.value} target="_blank">
            {params.value}
          </Link>
        );
      },
    },
    {
      field: 'createdAt',
      headerName: 'Created At',
      type: 'dateTime',
      width: 150,
      editable: false,
      valueFormatter: (params) => {
        return new Date(params.value).toDateString();
      },
    },
    {
      field: 'action',
      headerName: 'Action',
      sortable: false,
      width: 160,
      renderCell: (params) => {
        return <ActionField id={params.id} />;
      },
    },
  ];

  const detailFields = [
    { field: 'id', name: 'ID' },
    {
      field: 'name',
      name: 'Name',
    },
    {
      field: 'short_name',
      name: 'Short name',
    },
    {
      field: 'strata',
      name: 'Strata',
    },
    {
      field: 'campuses',
      name: 'Campuses',
      formatter: (data) => (
        <ul>
          {data.campuses.map((c, i) => (
            <li key={i}>{c.name}</li>
          ))}
        </ul>
      ),
    },
    {
      field: 'jenis_seleksi',
      name: 'Jenis seleksi',
    },
    {
      field: 'jenis_tanggungan',
      name: 'Jenis tanggungan',
    },
    {
      field: 'description',
      name: 'Description',
    },
    {
      field: 'mulai_pendaftaran',
      name: 'Mulai pendaftaran',
      formatter: (params) => {
        return new Date(params.mulai_pendaftaran).toDateString();
      },
    },
    {
      field: 'akhir_pendaftaran',
      name: 'Akhir pendaftaran',
      formatter: (params) => {
        return new Date(params.akhir_pendaftaran).toDateString();
      },
    },
    {
      field: 'pengumuman',
      name: 'Pengumuman',
      formatter: (params) => {
        return new Date(params.pengumuman).toDateString();
      },
    },
    {
      field: 'website',
      name: 'Website',
      formatter: (params) => {
        return (
          <Link href={params.website} target="_blank">
            {params.website}
          </Link>
        );
      },
    },
    {
      field: 'createdAt',
      name: 'Created At',
      formatter: (params) => {
        return new Date(params.createdAt).toDateString();
      },
    },
    {
      field: 'action',
      name: 'Action',
      formatter: (params) => {
        return <ActionField id={params.id} />;
      },
    },
  ];


  const queryKey = ['scholarships'];


  return (
    <>
      <Page title="Scholarships">
        <AddDeleteButton />
        <Box sx={{ height: 500, width: '100%' }}>
          <DataGrid
            components={{ Toolbar: GridToolbar }}
            rows={scholarships}
            columns={columns}
            pageSize={pageSize}
            rowsPerPageOptions={[10, 20, 30, 40, 50]}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            checkboxSelection
            disableSelectionOnClick
            experimentalFeatures={{ newEditingApi: true }}
            onSelectionModelChange={handleSelect}
            onCellClick={handleCellClick}
          />
        </Box>
        <ModalDelete handleDelete={deleteScholarshipApi} queryKey={queryKey} />
        <DetailCard fields={detailFields} data={scholarship} />
      </Page>
    </>
  );
}
