import { Autocomplete, Box, Button, TextField } from '@mui/material';
import Page from '../../components/Page';
import SaveIcon from '@mui/icons-material/Save';
import { useNavigate } from 'react-router-dom';
import { getRolesApi } from '../../services/api/rolesApi';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { userSchema } from '../../schema/user';
import { addUserApi } from '../../services/api/usersApi';
import { useMutation, useQuery } from '@tanstack/react-query';
import ProvincesCitiesForm from '../../components/ProvincesCitiesForm';
import mapOption from '../../utils/mapOption';

export default function AddUser() {
  const textFieldStyle = { mb: 2 };
  const navigate = useNavigate();

  const mutation = useMutation(addUserApi, {
    onSuccess: () => navigate('/users'),
    onError: (error) => {
      if (
        error.response.status === 400 &&
        error.response.data.message === 'Email already registered'
      ) {
        setError('email', {
          type: 'custom',
          message: error.response.data.message,
        });
      }
    },
  });
  const roles = useQuery(['roles'], getRolesApi);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
  } = useForm({
    resolver: joiResolver(userSchema),
    mode: 'onBlur',
  });

  const handleChangeRole = (_, value) => {
    if (value) {
      setValue('role_id', value.id);
    }
  };

  const handleAddUser = (data) => {
    mutation.mutate(data);
  };

  return (
    <>
      <Page
        title="Add User"
        items={[
          { name: 'Home', url: '/' },
          { name: 'Users', url: '/users' },
        ]}
      >
        {roles.isSuccess && (
          <Box
            component="form"
            sx={{ maxWidth: 500 }}
            onSubmit={handleSubmit(handleAddUser)}
          >
            <TextField
              required
              fullWidth
              label="Email"
              sx={textFieldStyle}
              error={!!errors.email}
              helperText={errors?.email?.message}
              {...register('email')}
            />
            <TextField
              required
              fullWidth
              label="Full Name"
              sx={textFieldStyle}
              error={!!errors.full_name}
              helperText={errors?.full_name?.message}
              {...register('full_name')}
            />

            <Autocomplete
              fullWidth
              options={roles.data.map(mapOption)}
              sx={textFieldStyle}
              onChange={handleChangeRole}
              renderInput={(params) => (
                <TextField required label="Role" {...params} />
              )}
            />

            <ProvincesCitiesForm setValue={setValue} />

            <TextField
              required
              fullWidth
              type="password"
              label="Password"
              sx={textFieldStyle}
              error={!!errors.password}
              helperText={errors?.password?.message}
              {...register('password')}
            />
            <TextField
              required
              fullWidth
              type="password"
              label="Confirm password"
              sx={textFieldStyle}
              error={!!errors.confirmPassword}
              helperText={errors?.confirmPassword?.message}
              {...register('confirmPassword')}
            />
            <Button type="submit" variant="contained" startIcon={<SaveIcon />}>
              Save
            </Button>
          </Box>
        )}
      </Page>
    </>
  );
}
