import { Autocomplete, Box, Button, TextField } from '@mui/material';
import Page from '../../components/Page';
import SaveIcon from '@mui/icons-material/Save';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { departmentSchema } from '../../schema/department';
import { addDepartmentApi } from '../../services/api/departmentsApi';
// import { getFacultiesApi } from '../../services/api/facultiesApi';
import { getCampusByIdApi, getCampusesApi } from '../../services/api/campusesApi';

export default function AddDepartment() {
  const navigate = useNavigate();

  const textFieldStyle = { mb: 2 };

  const [campuses, setCampuses] = useState([]);
  const [faculties, setFaculties] = useState([]);
  const [faculty, setFaculty] = useState(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: joiResolver(departmentSchema),
    mode: 'onBlur',
  });

  const handleSelectCampus =async (e, values) => {
    if(values) {
      const response = await getCampusByIdApi(values?.id)
      const facultyMapped = response?.faculties.map((f) => ({
        label: f.name,
        id: f.id,
      }));
      setFaculties(facultyMapped)
      setFaculty(null)
    } else {
      setFaculties([])
      setFaculty(null)
    }
  }

  const handleChangeFaculty = (e, value) => {
    if (value) {
      setFaculty(value)
      setValue('category_faculty_id', value.id);
    }
  };

  const handleAddDepartment = async (data) => {
    const response = await addDepartmentApi(data);
    if (response.success) navigate('/departments');
  };

  const getData = useCallback(async () => {
    // const responseFaculties = await getFacultiesApi();
    // const facultiesMapped = responseFaculties.map((faculty) => ({
    //   label: faculty.name,
    //   id: faculty.id,
    // }));
    // setFaculties(facultiesMapped);
    const responseCampuses = await getCampusesApi()
    const campuseMapped = responseCampuses?.map((c) => ({
      label: c?.name,
      id: c?.id
    }))
    setCampuses(campuseMapped)
  }, []);


  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <>
      <Page
        title="Add Department"
        items={[
          { name: 'Home', url: '/' },
          { name: 'Departments', url: '/departments' },
        ]}
      >
        <Box
          component="form"
          sx={{ maxWidth: 500 }}
          onSubmit={handleSubmit(handleAddDepartment)}
        >
          <TextField
            required
            fullWidth
            label="Name"
            sx={textFieldStyle}
            error={!!errors.name}
            helperText={errors?.name?.message}
            {...register('name')}
          />
          <TextField
            fullWidth
            label="Short name"
            sx={textFieldStyle}
            error={!!errors.short_name}
            helperText={errors?.short_name?.message}
            {...register('short_name')}
          />
          <TextField
            fullWidth
            label="Website"
            sx={textFieldStyle}
            error={!!errors.website}
            helperText={errors?.website?.message}
            {...register('website')}
          />
          <Autocomplete
            fullWidth
            onChange={handleSelectCampus}
            options={campuses}
            sx={textFieldStyle}
            renderInput={(params) => (
              <TextField required {...params} label="Kampus" />
            )}
          />

          <Autocomplete
            fullWidth
            value={faculty}
            options={faculties}
            sx={textFieldStyle}
            onChange={handleChangeFaculty}
            renderInput={(params) => (
              <TextField required label="Faculty" {...params} />
            )}
          />
          <Button type="submit" variant="contained" startIcon={<SaveIcon />}>
            Save
          </Button>
        </Box>
      </Page>
    </>
  );
}
