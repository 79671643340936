import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import {
  deleteScholarshipApi,
  getScholarshipsApi,
} from '../../services/api/scholarshipsApi';

export const getScholarships = createAsyncThunk(
  '/scholarships/getScholarships',
  async () => {
    const response = await getScholarshipsApi();
    return response.result;
  }
);
export const deleteScholarship = createAsyncThunk(
  '/scholarships/deleteScholarship',
  async (id) => {
    await deleteScholarshipApi(id);
    return id;
  }
);

const scholarshipEntity = createEntityAdapter({
  selectId: (scholarship) => scholarship.id,
});

export const scholarshipSlice = createSlice({
  name: 'scholarship',
  initialState: scholarshipEntity.getInitialState(),
  extraReducers: {
    [getScholarships.fulfilled]: (state, action) => {
      scholarshipEntity.setAll(state, action.payload);
    },
    [deleteScholarship.fulfilled]: (state, action) => {
      scholarshipEntity.removeOne(state, action.payload);
    },
  },
});

export const scholarshipSelector = scholarshipEntity.getSelectors(
  (state) => state.scholarship
);

export default scholarshipSlice.reducer;
