import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputBase,
  TextField,
  Typography,
} from '@mui/material';
import Page from '../../components/Page';
import SaveIcon from '@mui/icons-material/Save';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { scholarshipEditSchema } from '../../schema/scholarship';
import {
  editScholarshipApi,
  getScholarshipByIdApi,
} from '../../services/api/scholarshipsApi';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { getCampusesApi } from '../../services/api/campusesApi';
import { getCategoryScholarshipsApi } from '../../services/api/categoryScholarshipsApi';
import { grey } from '@mui/material/colors';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

// import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';

export default function EditScholarship() {
  const { id } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const queryKey = ['scholarships', id];
  const textFieldStyle = { mb: 2 };

  const [campuses, setCampuses] = useState([]);
  const [categoryScholarships, setCategoryScholarships] = useState([]);
  const [category, setCategory] = useState(null);
  const [campus, setCampus] = useState([]);
  const [dateTime, setDateTime] = useState({
    mulai_pendaftaran: null,
    akhir_pendaftaran: null,
    // pengumuman: null,
  });

  const [strata, setStrata] = useState([false, false, false, false])
  const [checkedState, setCheckedState] = useState(
    new Array([{label: 'Diploma', val: 'diploma'},{label: 'S1', val: 's1'}, {label: 'S2', val: 's2'}, {label: 'S3', val: 's3'}].length).fill(false)
  );

  const [logo, setLogo] = useState(null);

  const [description, setDescription] = useState("");
  const [pendaftaran, setPandaftaran] = useState("");
  const [documents, setDocuments] = useState("");
  const [benefits, setBenefits] = useState("");
  const [persyaratan, setPesyaratan] = useState("");
  const [timeline, setTimeline] = useState('');


  
  const mutation = useMutation(editScholarshipApi, {
    // onSuccess: () => navigate('/scholarships'),
    onSuccess: () => {
      navigate('/scholarships');
      queryClient.invalidateQueries(queryKey);
    },
  });


  // const {
  //   register,
  //   handleSubmit,
  //   formState: { errors },
  //   setValue,
  // } = useForm(
  //   {
  //   resolver: joiResolver(scholarshipEditSchema),
  //   mode: 'onBlur',
  // }
  // );

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();


  const handleLogoInput = (e) => {
    console.log(e.target.files[0], 'e.target.files[0]');
    setLogo(e.target.files[0]);
    setValue('image', e.target.files[0]);
  };



  const handleOnChange = (position) => {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    );

    setCheckedState(updatedCheckedState);
  }

  

  const handleChangeDateTime = (name, value) => {
    setDateTime({ ...dateTime, [name]: value });
    setValue(name, new Date(value));
  };



  const handleChangeCampuses = (e, value) => {
    if (value) {
      setCampus(value);
      setValue(
        'santrihub_campus_id',
        value.map((v) => v.id)
      );
    }
  };

  const handleChangeCategoryScholarship = (e, value) => {
    if (value) {
      setCategory(value);
      setValue('category_scholarship_id', value.id);
    }
  };

  const handleEditScholarship = async (data) => {
    data.santrihub_campus_id = campus.map(i => i.id);

    data.diploma = checkedState[0];
    data.strata_1 = checkedState[1];
    data.strata_2 = checkedState[2];
    data.strata_3 = checkedState[3];
    data.description = description;
    data.benefits = benefits;
    data.cara_pendaftaran = pendaftaran;
    data.documents = documents;
    data.persyaratan = persyaratan;
    data.timeline = timeline;


    console.log({id, data}, 'data edit');
    mutation.mutate({id, data})
    // const response = await editScholarshipApi(id, data);
    // if (response.success) navigate('/scholarships');
  };

  const getData = useCallback(async () => {
    const responseScholarship = await getScholarshipByIdApi(id);
    const {
      name,
      short_name,
      strata,
      jenis_seleksi,
      jenis_tanggungan,
      description,
      mulai_pendaftaran,
      akhir_pendaftaran,
      timeline,
      // pengumuman,
      documents,
      cara_pendaftaran,
      persyaratan,
      benefits,
      website,
      logo,
      category_scholarship_id,
      category_scholarship,
      santrihub_campus_id,
      campuses,
      diploma, 
      strata_1,
      strata_2,
      strata_3
    } = responseScholarship.result;
    console.log(responseScholarship.result);

    setDocuments(documents);
    setDescription(description);
    setPandaftaran(cara_pendaftaran)
    setTimeline(timeline)
    setDocuments(documents)
    setPesyaratan(persyaratan)
    setBenefits(benefits)


    let setDiploma = diploma ? diploma : false
    let setStrata_1 = strata_1
    let setStrata_2 = strata_2
    let setStrata_3 = strata_3

    setStrata([setDiploma,setStrata_1,setStrata_2,setStrata_3])
        

    setCategory({
      label: category_scholarship.name,
      id: category_scholarship_id,
    });
    setCampus(
      campuses.map((campus) => ({ id: campus.id, label: campus.name }))
    );
    setDateTime({ mulai_pendaftaran, akhir_pendaftaran });
    // setLogo(logo);

    const scholarship = {
      name,
      short_name,
      strata,
      jenis_seleksi,
      jenis_tanggungan,
      description,
      mulai_pendaftaran,
      akhir_pendaftaran,
      // pengumuman,
      website,
      category_scholarship_id,
      santrihub_campus_id,
    };

    for (const key in scholarship) {
      setValue(key, scholarship[key]);
    }

    const responseCampuses = await getCampusesApi();
    const campusesMapped = responseCampuses.map((campus) => ({
      label: campus.name,
      id: campus.id,
    }));
    setCampuses(campusesMapped);

    const responseCategoryScholarships = await getCategoryScholarshipsApi();
    const categoryScholarshipsMapped = responseCategoryScholarships.map(
      (campus) => ({
        label: campus.name,
        id: campus.id,
      })
    );
    setCategoryScholarships(categoryScholarshipsMapped);
  }, [id, setValue]);

  useEffect(() => {
    getData();
  }, [getData]);


  return (
    <>
      <Page
        title="Edit Scholarship"
        items={[
          { name: 'Home', url: '/' },
          { name: 'Scholarships', url: '/scholarships' },
        ]}
      >
        <Box
          component="form"
          sx={{ maxWidth: 500 }}
          onSubmit={handleSubmit(handleEditScholarship)}
        >
          <TextField
            required
            fullWidth
            // label="Name"
            sx={textFieldStyle}
            error={!!errors.name}
            helperText={errors?.name?.message}
            {...register('name')}
          />
          <TextField
            fullWidth
            // label="Short name"
            sx={textFieldStyle}
            error={!!errors.short_name}
            helperText={errors?.short_name?.message}
            {...register('short_name')}
          />
          {/* <TextField
            fullWidth
            label="Strata"
            sx={textFieldStyle}
            error={!!errors.strata}
            helperText={errors?.strata?.message}
            {...register('strata')}
          /> */}
             {/* <div>
            <Typography variant="h6" component="h6">Jenjang</Typography>
            <p style={{fontStyle: 'italic'}} >*Jangan lupa di cek list*</p>
           {[{label: 'Diploma', val: 'diploma'},{label: 'S1', val: 's1'}, {label: 'S2', val: 's2'}, {label: 'S3', val: 's3'}].map((i, e) => {
            return (
              <FormControlLabel key={e}
              control={<Checkbox />}
              label={i.label}
              value={i.val}
              
              checked={checkedState[e]}
              // checked={strata[e]}
              onChange={() => handleOnChange(e)}
              labelPlacement="start"
              required
            />
            )
           })}
          </div> */}
          <br />
          {/* <TextField
            fullWidth
            // label="Jenis seleksi"
            sx={textFieldStyle}
            error={!!errors.jenis_seleksi}
            helperText={errors?.jenis_seleksi?.message}
            {...register('jenis_seleksi')}
          /> */}
          {/* <TextField
            fullWidth
            // label="Jenis tanggungan"
            sx={textFieldStyle}
            error={!!errors.jenis_tanggungan}
            helperText={errors?.jenis_tanggungan?.message}
            {...register('jenis_tanggungan')}
          /> */}
          {/* <TextField
            fullWidth
            // label="Description"
            sx={textFieldStyle}
            error={!!errors.description}
            helperText={errors?.description?.message}
            {...register('description')}
          /> */}
          <TextField
            fullWidth
            // label="Website"
            sx={textFieldStyle}
            error={!!errors.website}
            helperText={errors?.website?.message}
            {...register('website')}
          />
             <div>
            <p>Jenjang</p>
           {[{label: 'Diploma', val: 'diploma'},{label: 'S1', val: 's1'}, {label: 'S2', val: 's2'}, {label: 'S3', val: 's3'}].map((i, e) => {
            return (
              <FormControlLabel key={e}
              control={<Checkbox />}
              label={i.label}
              value={i.val}
              checked={checkedState[e]}
              onChange={() => handleOnChange(e)}
              labelPlacement="start"
              required={true}
            />
            )
           })}
          </div>
          <br />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Mulai pendaftaran"
              views={['year', 'month', 'day']}
              value={dateTime.mulai_pendaftaran}
              onChange={(value) =>
                handleChangeDateTime('mulai_pendaftaran', value)
              }
              renderInput={(params) => (
                <TextField fullWidth required sx={textFieldStyle} {...params} />
              )}
            />
            <DatePicker
              label="Akhir pendaftaran"
              value={dateTime.akhir_pendaftaran}
              onChange={(value) =>
                handleChangeDateTime('akhir_pendaftaran', value)
              }
              renderInput={(params) => (
                <TextField fullWidth required sx={textFieldStyle} {...params} />
              )}
            />
            {/* <DatePicker
              label="Pengumuman"
              value={dateTime.pengumuman}
              onChange={(value) => handleChangeDateTime('pengumuman', value)}
              renderInput={(params) => (
                <TextField fullWidth required sx={textFieldStyle} {...params} />
              )}
            /> */}
          </LocalizationProvider>
          <Autocomplete
            fullWidth
            options={categoryScholarships}
            sx={textFieldStyle}
            value={category}
            onChange={handleChangeCategoryScholarship}
            // to avoid warning "None of the options match with"
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField required label="Category scholarship" {...params} />
            )}
          />
          <Autocomplete
            fullWidth
            multiple
            options={campuses}
            sx={textFieldStyle}
            value={campus}
            onChange={handleChangeCampuses}
            // to avoid warning "None of the options match with"
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => <TextField label="Campuses" {...params} />}
          />
          <label htmlFor="logo">
            <Box sx={{ display: 'flex' }}>
              <Typography
                sx={{
                  width: '50%',
                  padding: 1,
                  paddingLeft: 2,
                  border: `solid ${grey[400]}`,
                  borderWidth: '0.5px',
                  mr: 2,
                }}
              >
                Logo
              </Typography>
              <AddPhotoAlternateIcon fontSize="large" />
            </Box>
          </label>
          <InputBase
            fullWidth
            id="logo"
            type="file"
            accept="image/png, image/jpeg, image/webp, image/gif"
            onChange={handleLogoInput}
            sx={{ ...textFieldStyle, mt: 1 }}
          />
          {typeof logo === 'string' && (
            <img
              src={logo}
              alt=""
              style={{ width: '100%', marginBottom: 15 }}
            />
          )}
          {logo && typeof logo !== 'string' && (
            <img
              src={URL.createObjectURL(logo)}
              alt=""
              style={{ width: '100%', marginBottom: 15 }}
              onLoad={(e) => URL.revokeObjectURL(e.target.src)}
            />
          )}
           <div style={{ width: "100%%" }}>
          <div>
              <h4>Deskripsi</h4>
              <CKEditor
                editor={ClassicEditor}
                data={description}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setDescription(data);
                }}
              />
            </div>
            <div>
              <h4>Cara Pendaftaran</h4>
              <CKEditor
                editor={ClassicEditor}
                data={pendaftaran}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setPandaftaran(data);
                }}
              />
            </div>
            <div>
              <h4>Dokumen / Berkas</h4>
              <CKEditor
                editor={ClassicEditor}
                data={documents}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setDocuments(data);
                }}
              />
            </div>
            <div>
              <h4>Benefit / Keuntungan</h4>
              <CKEditor
                editor={ClassicEditor}
                data={benefits}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setBenefits(data);
                }}
              />
            </div>
            <div>
              <h4>Persyaratan</h4>
              <CKEditor
                editor={ClassicEditor}
                data={persyaratan}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setPesyaratan(data);
                }}
              />
            </div>
            <div>
              <h4>Timeline</h4>
              <CKEditor
                editor={ClassicEditor}
                data={timeline}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setTimeline(data);
                }}
              />
            </div>
          </div>
          <br />

          <Button type="submit" variant="contained" startIcon={<SaveIcon />}>
            Save
          </Button>
        </Box>
      </Page>
    </>
  );
}
