import {
  Autocomplete,
  Box,
  Button,
  FormControlLabel,
  TextField,
  Checkbox,
  Typography,
} from '@mui/material';
import Page from '../../components/Page';
import SaveIcon from '@mui/icons-material/Save';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { joiResolver } from '@hookform/resolvers/joi';

import { jalurMasukSchema } from '../../schema/jalurMasuk';
import {
  editJalurMasukApi,
  getJalurMasukByIdApi,
} from '../../services/api/jalurMasuksApi';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { getCampusesApi } from '../../services/api/campusesApi';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import mapOption from '../../utils/mapOption';

const configEditor = {
  toolbar: [
    "undo",
    "redo",
    "|",
    "heading",
    "|",
    "insertTable",
    "|",
    "fontfamily",
    "fontsize",
    "fontColor",
    "fontBackgroundColor",
    "|",
    "bold",
    "italic",
    "strikethrough",
    "subscript",
    "superscript",
    "code",
    "|",
    "link",
    "blockQuote",
    "codeBlock",
    "|",
    "bulletedList",
    "numberedList",
    "alignment",
    "todoList",
    "outdent",
    "indent",
  ],
  shouldNotGroupWhenFull: false,
};


export default function EditJalurMasuk() {
  const { id } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const queryKey = ['jalur-masuk', id];
  const [campuses, setCampuses] = useState({
    PTN: [],
    PTKIN: [],
    PTK: [],
    PTS: [],
    POLTEK: [],
  });

  const [description, setDescription] = useState("");
  const [pendaftaran, setPandaftaran] = useState("");
  const [persyaratan, setPesyaratan] = useState("");
  const [timeline, setTimeline] = useState("");

  const [checkedState, setCheckedState] = useState(
    new Array([{label: 'Diploma', val: 'diploma'},{label: 'S1', val: 's1'}, {label: 'S2', val: 's2'}, {label: 'S3', val: 's3'}].length).fill(false)
  );

  console.log({checkedState});

  const textFieldStyle = { mb: 2 };

  const { data: jalurMasukData, isSuccess } = useQuery(
    queryKey,
    () => getJalurMasukByIdApi(id),
    {
      onSuccess: (data) => {
        setTahapan(data.tahapan);
        // setValue('tahapan', data.tahapan);
        setCampuses({
          'Perguruan Tinggi Negeri': data.campuses
          .filter((c) => c.category_campus.name === "Perguruan Tinggi Negeri")
          .map(mapOption),
        'Perguruan Tinggi Keagamaan Islam Negeri': data.campuses
          .filter((c) => c.category_campus.name === "Perguruan Tinggi Keagamaan Islam Negeri")
          .map(mapOption),
        'Peguruan Tinggi Kedinasan': data.campuses
          .filter((c) => c.category_campus.name === "Peguruan Tinggi Kedinasan")
          .map(mapOption),
        'Perguruan Tinggi Swasta': data.campuses
          .filter((c) => c.category_campus.name === "Perguruan Tinggi Swasta")
          .map(mapOption),
        'Politeknik Negeri': data.campuses
          .filter((c) => c.category_campus.name === "Politeknik Negeri")
          .map(mapOption),
        });
        const dateTime = {
          mulai_pendaftaran: data.mulai_pendaftaran,
          akhir_pendaftaran: data.akhir_pendaftaran,
          pengumuman: data.pengumuman,
        };
        setDateTime(dateTime);
        for (const key in dateTime) {
          setValue(key, dateTime[key]);
        }
      },
      refetchOnWindowFocus: false,
    }
  );

  const campusesData = useQuery(['campuses'], getCampusesApi);

  // console.log({campusesData});
  const mutation = useMutation(editJalurMasukApi, {
    onSuccess: () => {
      navigate('/jalur-masuk');
      queryClient.invalidateQueries(queryKey);
    },
  });


  const [dateTime, setDateTime] = useState({});
  const [tahapan, setTahapan] = useState(['']);

  // const handleAddTahapan = () => {
  //   setTahapan(tahapan.concat(''));
  // };
  // const handleDeleteTahapan = () => {
  //   setTahapan((t) => t.slice(0, -1));
  //   unregister(`tahapan[${tahapan.length - 1}]`);
  // };

  const handleChangeDateTime = (name, value) => {
    setDateTime({ ...dateTime, [name]: value });
    setValue(name, new Date(value));
  };

  const handleOnChangeStrata = (position) => {
    const updatedCheckedState = checkedState.map((item, index) =>
    index === position ? !item : item
  );

  setCheckedState(updatedCheckedState);
  }

  // const {
  //   register,
  //   unregister,
  //   handleSubmit,
  //   formState: { errors },
  //   setValue,
  // } = useForm({
  //   resolver: joiResolver(jalurMasukSchema),
  //   mode: 'onBlur',
  // });

  const {
    register,
    unregister,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const handleChangeCampuses = (name, value) => {
    if (value) {
      setCampuses((c) => ({ ...c, [name]: value }));
    }
  };

  const handleEditJalurMasuk = (data) => {
    if(description) {
      data.description = description
    }

    if(persyaratan) {
      data.persyaratan = persyaratan
    }

    if(pendaftaran) {
      data.pendaftaran = pendaftaran
    }

    if(timeline) {
      data.timeline = timeline
    }

    data.diploma = checkedState[0];
    data.strata_1 = checkedState[1];
    data.strata_2 = checkedState[2];
    data.strata_3 = checkedState[3];



    let santrihub_campus_id = [];
    for (const key in campuses) {
      santrihub_campus_id = santrihub_campus_id.concat(
        campuses[key].map((c) => c.id)
      );
    }
    mutation.mutate({ id, data: { ...data, santrihub_campus_id } });
  };

  return (
    <>
      <Page
        title="Edit Jalur masuk"
        items={[
          { name: 'Home', url: '/' },
          { name: 'Jalur masuk', url: '/jalur-masuk' },
        ]}
      >
        {isSuccess && campusesData.isSuccess && (
          <Box
            component="form"
            sx={{ maxWidth: 500 }}
            onSubmit={handleSubmit(handleEditJalurMasuk)}
          >
            <TextField
              required
              fullWidth
              label="Name"
              sx={textFieldStyle}
              error={!!errors.name}
              helperText={errors?.name?.message}
              {...register('name', { value: jalurMasukData.name })}
            />
            <TextField
              fullWidth
              label="Short name"
              sx={textFieldStyle}
              error={!!errors.short_name}
              helperText={errors?.short_name?.message}
              {...register('short_name', { value: jalurMasukData.short_name })}
            />
             <div>
            <p>Jenjang</p>
           {[{label: 'Diploma', val: 'diploma'},{label: 'S1', val: 's1'}, {label: 'S2', val: 's2'}, {label: 'S3', val: 's3'}].map((i, e) => {
            // return (
            //   <FormControlLabel key={e}
            //   control={<CheckBox />}
            //   label={i.label}
            //   // value={i.val}
            //   checked={checkedState[e]}
            //   onChange={() => handleOnChangeStrata(e)}
            //   labelPlacement="start"
            //   required={true}
            // />
            // )
            return (
              <FormControlLabel key={e}
              control={<Checkbox />}
              label={i.label}
              value={i.val}
              checked={checkedState[e]}
              onChange={() => handleOnChangeStrata(e)}
              labelPlacement="start"
              required={true}
            />
            )
           })}
          </div>
          <br/>
          
            {/* <TextField
              fullWidth
              label="Strata"
              sx={textFieldStyle}
              error={!!errors.strata}
              helperText={errors?.strata?.message}
              {...register('strata', { value: jalurMasukData.strata })}
            />
            <TextField
              fullWidth
              label="Jenis seleksi"
              sx={textFieldStyle}
              error={!!errors.jenis_seleksi}
              helperText={errors?.jenis_seleksi?.message}
              {...register('jenis_seleksi', {
                value: jalurMasukData.jenis_seleksi,
              })}
            /> */}
            {/* <TextField
              fullWidth
              label="Description"
              sx={textFieldStyle}
              error={!!errors.description}
              helperText={errors?.description?.message}
              {...register('description', {
                value: jalurMasukData.description,
              })}
            /> */}
            <Box>
              <Typography variant="h6" sx={{ mb: 1.5 }}>
                Tahapan - Tahapan
              </Typography>
              <div style={{ width: "130%" }}>
                <div>
                  <h4>Deskripsi</h4>
                  <CKEditor
                    editor={ClassicEditor}
                    config={configEditor}
                  
                    data={jalurMasukData.description}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setDescription(data);
                    }}
                  />
                </div>
              </div>
              <div style={{ width: "130%" }}>
                <div>
                  <h4>Persyaratan</h4>
                  <CKEditor
                    editor={ClassicEditor}
                    config={configEditor}
                    data={jalurMasukData.persyaratan}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setPesyaratan(data);
                    }}
                  />
                </div>
              </div>

              <div style={{ width: "130%" }}>
                <div>
                  <h4>Cara Pendaftaran</h4>
                  <CKEditor
                    editor={ClassicEditor}
                    config={configEditor}
                    data={jalurMasukData.pendaftaran}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setPandaftaran(data);
                    }}
                  />
                </div>
              </div>

              <div style={{ width: "130%", marginBottom: 40 }}>
                <div>
                  <h4>Timeline</h4>
                  <CKEditor
                    editor={ClassicEditor}
                    config={configEditor}
                    data={jalurMasukData.timeline}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setTimeline(data);
                    }}
                  />
                </div>
              </div>
            </Box>
            {/* {tahapan.map((t, i) => (
              <Box
                sx={{ ml: 2, mb: 2, display: 'flex', alignItems: 'center' }}
                key={i}
              >
                <TextField
                  label={`Tahapan ${i + 1}`}
                  sx={{ flexGrow: 1 }}
                  {...register(`tahapan[${i}]`)}
                />
                {i + 1 === tahapan.length && (
                  <>
                    {i !== 0 && (
                      <Button
                        variant="contained"
                        size="small"
                        color="error"
                        sx={{ ml: 1 }}
                        onClick={handleDeleteTahapan}
                      >
                        Hapus
                      </Button>
                    )}
                    <Button
                      variant="contained"
                      size="small"
                      sx={{ ml: 1 }}
                      onClick={handleAddTahapan}
                    >
                      Tambah
                    </Button>
                  </>
                )}
              </Box>
            ))} */}
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                label="Mulai pendaftaran"
                value={dateTime.mulai_pendaftaran}
                onChange={(value) =>
                  handleChangeDateTime('mulai_pendaftaran', value)
                }
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    required
                    sx={textFieldStyle}
                    {...params}
                  />
                )}
              />
              <DateTimePicker
                label="Akhir pendaftaran"
                value={dateTime.akhir_pendaftaran}
                onChange={(value) =>
                  handleChangeDateTime('akhir_pendaftaran', value)
                }
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    required
                    sx={textFieldStyle}
                    {...params}
                  />
                )}
              />
              {/* <DateTimePicker
                label="Pengumuman"
                value={dateTime.pengumuman}
                onChange={(value) => handleChangeDateTime('pengumuman', value)}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    required
                    sx={textFieldStyle}
                    {...params}
                  />
                )}
              /> */}
            </LocalizationProvider>
            <TextField
              fullWidth
              label="Website"
              sx={textFieldStyle}
              error={!!errors.website}
              helperText={errors?.website?.message}
              {...register('website', { value: jalurMasukData.website })}
            />
            <TextField
              required
              fullWidth
              label="Biaya"
              type="number"
              sx={textFieldStyle}
              error={!!errors.biaya}
              helperText={errors?.biaya?.message}
              {...register('biaya', { value: jalurMasukData.biaya })}
            />
            {/* Campuses */}
            <Typography variant="h6" sx={{ mb: 1.5 }}>
              Campuses
            </Typography>
            <CampusesField
              name="Perguruan Tinggi Negeri"
              campusesData={campusesData}
              campuses={campuses}
              textFieldStyle={textFieldStyle}
              handleChangeCampuses={handleChangeCampuses}
            />
            <CampusesField
                name="Perguruan Tinggi Keagamaan Islam Negeri"
              campusesData={campusesData}
              campuses={campuses}
              textFieldStyle={textFieldStyle}
              handleChangeCampuses={handleChangeCampuses}
            />
            <CampusesField
            name="Peguruan Tinggi Kedinasan"
              campusesData={campusesData}
              campuses={campuses}
              textFieldStyle={textFieldStyle}
              handleChangeCampuses={handleChangeCampuses}
            />
            <CampusesField
              name="Perguruan Tinggi Swasta"
              campusesData={campusesData}
              campuses={campuses}
              textFieldStyle={textFieldStyle}
              handleChangeCampuses={handleChangeCampuses}
            />
            <CampusesField
               name="Politeknik Negeri"
              campusesData={campusesData}
              campuses={campuses}
              textFieldStyle={textFieldStyle}
              handleChangeCampuses={handleChangeCampuses}
            />
            <Button type="submit" variant="contained" startIcon={<SaveIcon />}>
              Save
            </Button>
          </Box>
        )}
      </Page>
    </>
  );
}

function CampusesField({
  name,
  campusesData,
  campuses,
  textFieldStyle,
  handleChangeCampuses,
}) {

  return (
    <Autocomplete
      fullWidth
      multiple
      options={campusesData.data
        .filter((c) => c.category_campus_name === name)
        .map(mapOption)}
      sx={textFieldStyle}
      value={campuses[name] ? campuses[name] : []}
      onChange={(_, value) => handleChangeCampuses(name, value)}
      // to avoid warning "None of the options match with"
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderInput={(params) => <TextField label={name} {...params} />}
    />
  );
}
