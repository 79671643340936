import axios from '../../utils/axiosAuth';
import config from '../../utils/config';

const { URL_API } = config;
const ENDPOINT = 'jalur_masuk';

export const getJalurMasuksApi = async () => {
  try {
    const { data } = await axios({
      method: 'get',
      url: `${URL_API}/${ENDPOINT}`,
    });

    return data.result;
  } catch (error) {
    return error.response.data;
  }
};

export const addJalurMasukApi = async (jalurMasuksData) => {
  try {
    const { data } = await axios({
      method: 'post',
      url: `${URL_API}/${ENDPOINT}`,
      data: jalurMasuksData,
    });

    return data;
  } catch (error) {
    return error.response.data;
  }
};

export const getJalurMasukByIdApi = async (id) => {
  try {
    const { data } = await axios({
      method: 'get',
      url: `${URL_API}/${ENDPOINT}/${id}`,
    });

    return data.result;
  } catch (error) {
    return error.response.data;
  }
};

export const editJalurMasukApi = async ({ id, data: jalurMasuksData }) => {
  try {
    const { data } = await axios({
      method: 'put',
      url: `${URL_API}/${ENDPOINT}/${id}`,
      data: jalurMasuksData,
    });

    return data;
  } catch (error) {
    return error.response.data;
  }
};

export const deleteJalurMasukApi = async (id) => {
  try {
    const { data } = await axios({
      method: 'delete',
      url: `${URL_API}/${ENDPOINT}/${id}`,
    });

    return data;
  } catch (error) {
    return error.response.data;
  }
};
