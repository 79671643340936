import {
  Autocomplete,
  Box,
  Button,
  // InputBase,
  TextField,
  // Typography,
} from '@mui/material';
import Page from '../../components/Page';
import SaveIcon from '@mui/icons-material/Save';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { facultySchema } from '../../schema/faculty';
import { addFacultyApi } from '../../services/api/facultiesApi';
// import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
// import { grey } from '@mui/material/colors';
import { getCampusesApi } from '../../services/api/campusesApi';

export default function AddFaculty() {
  const navigate = useNavigate();

  const textFieldStyle = { mb: 2 };

  const [campuses, setCampuses] = useState([]);
  // const [logo, setLogo] = useState(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: joiResolver(facultySchema),
    mode: 'onBlur',
  });

  const handleChangeCampus = (e, value) => {
    if (value) {
      setValue('santrihub_campus_id', value.id);
    }
  };

  const handleAddFaculty = async (data) => {
    const response = await addFacultyApi(data);
    if (response.success) navigate('/faculties');
  };

  // const handleLogoInput = (e) => {
  //   setLogo(e.target.files[0]);
  //   setValue('image', e.target.files[0]);
  // };

  const getData = useCallback(async () => {
    const responseCampuses = await getCampusesApi();
    const campusesMapped = responseCampuses.map((campus) => ({
      label: campus.name,
      id: campus.id,
    }));
    setCampuses(campusesMapped);
  }, []);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <>
      <Page
        title="Add Faculty"
        items={[
          { name: 'Home', url: '/' },
          { name: 'Faculties', url: '/faculties' },
        ]}
      >
        <Box
          component="form"
          sx={{ maxWidth: 500 }}
          onSubmit={handleSubmit(handleAddFaculty)}
        >
          <TextField
            required
            fullWidth
            label="Name"
            sx={textFieldStyle}
            error={!!errors.name}
            helperText={errors?.name?.message}
            {...register('name')}
          />
          <TextField
            fullWidth
            label="Short name"
            sx={textFieldStyle}
            error={!!errors.short_name}
            helperText={errors?.short_name?.message}
            {...register('short_name')}
          />
          <TextField
            fullWidth
            label="Website"
            sx={textFieldStyle}
            error={!!errors.website}
            helperText={errors?.website?.message}
            {...register('website')}
          />
          {/* <TextField
            fullWidth
            label="Description"
            sx={textFieldStyle}
            error={!!errors.description}
            helperText={errors?.description?.message}
            {...register('description')}
          /> */}
          <Autocomplete
            fullWidth
            options={campuses}
            sx={textFieldStyle}
            onChange={handleChangeCampus}
            renderInput={(params) => (
              <TextField required label="Campus" {...params} />
            )}
          />
          {/* <label htmlFor="logo">
            <Box sx={{ display: 'flex' }}>
              <Typography
                sx={{
                  width: '50%',
                  padding: 1,
                  paddingLeft: 2,
                  border: `solid ${grey[400]}`,
                  borderWidth: '0.5px',
                  mr: 2,
                }}
              >
                Logo
              </Typography>
              <AddPhotoAlternateIcon fontSize="large" />
            </Box>
          </label> */}
          {/* <InputBase
            fullWidth
            id="logo"
            type="file"
            accept="image/png, image/jpeg, image/webp, image/gif"
            onChange={handleLogoInput}
            sx={{ ...textFieldStyle, mt: 1 }}
          />
          {logo && (
            <img
              src={URL.createObjectURL(logo)}
              alt=""
              style={{ width: '100%', marginBottom: 15 }}
              onLoad={(e) => URL.revokeObjectURL(e.target.src)}
            />
          )} */}
          <Button type="submit" variant="contained" startIcon={<SaveIcon />}>
            Save
          </Button>
        </Box>
      </Page>
    </>
  );
}
