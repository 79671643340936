import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Box, Collapse, Menu, MenuItem } from '@mui/material';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import GroupIcon from '@mui/icons-material/Group';
import { useNavigate } from 'react-router-dom';
import CategoryIcon from '@mui/icons-material/Category';
import SchoolIcon from '@mui/icons-material/School';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import DomainIcon from '@mui/icons-material/Domain';
import InputIcon from '@mui/icons-material/Input';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import MosqueIcon from '@mui/icons-material/Mosque';
import { useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import {
  setSidebarOpen,
  sidebarSelector,
} from '../redux/features/sidebarSlice';
import MenuListItem from './MenuListItem';
import { getLocalUser } from '../services/token';

const drawerWidth = 220;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

export default function MenuDrawer() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { sidebarOpen } = useSelector(sidebarSelector);

  const handleSidebarClose = () => {
    dispatch(setSidebarOpen(false));
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = (to) => {
    setAnchorEl(null);
    navigate(to);
  };

  const [openCategory, setOpenCategory] = useState(false);

  const handleOpenCategory = () => {
    dispatch(setSidebarOpen(true));
    setOpenCategory(!openCategory);
  };

  const listMenu = [
    {
      text: 'Roles',
      icon: <ManageAccountsIcon />,
      to: '/roles',
      superAdminOnly: true,
    },
    { text: 'User', icon: <GroupIcon />, to: '/users', superAdminOnly: true },

    {
      text: 'Kampus',
      icon: <SchoolIcon />,
      to: '/campuses',
    },
    {
      text: 'Fakultas',
      icon: <AccountBalanceIcon />,
      to: '/faculties',
    },
    {
      text: 'Jurusan',
      icon: <DomainIcon />,
      to: '/departments',
    },
    {
      text: 'Jalur masuk',
      icon: <InputIcon />,
      to: '/jalur-masuk',
    },

    {
      text: 'Beasiswa',
      icon: <AccountBalanceWalletIcon />,
      to: '/scholarships',
    },
    // {
    //   text: 'Organizations',
    //   icon: <SupervisedUserCircleIcon />,
    //   to: '/organizations',
    // },
    // {
    //   text: 'Pesantren',
    //   icon: <MosqueIcon />,
    //   to: '/pesantren',
    // },
  ];

  const listCategory = [
    {
      text: 'Campuses',
      icon: <SchoolIcon />,
      to: '/category-campuses',
    },
    {
      text: 'Scholarships',
      icon: <AccountBalanceWalletIcon />,
      to: '/category-scholarships',
    },
  ];

  const list = () => (
    <List>
      {listMenu.map((item, i) => {
        if (item.superAdminOnly) {
          const isSuperAdmin = getLocalUser().role_id === 1;
          if (isSuperAdmin) {
            return (
              <MenuListItem
                icon={item.icon}
                text={item.text}
                to={item.to}
                key={i}
              />
            );
          }
          return '';
        }
        return (
          <MenuListItem
            icon={item.icon}
            text={item.text}
            to={item.to}
            key={i}
          />
        );
      })}

      <MenuListItem
        icon={<CategoryIcon />}
        text="Kategori"
        onClick={handleOpenCategory}
      >
        {sidebarOpen && openCategory ? (
          <ExpandLess />
        ) : sidebarOpen ? (
          <ExpandMore />
        ) : null}
      </MenuListItem>
      {/* Category menu */}
      <Collapse in={openCategory} timeout="auto" unmountOnExit>
        <List disablePadding>
          {listCategory.map((item, i) => (
            <MenuListItem
              icon={item.icon}
              text={item.text}
              to={item.to}
              key={i}
              sx={{ pl: 3 }}
            />
          ))}
        </List>
      </Collapse>

      <MenuListItem
        icon={<MenuIcon />}
        text="Menu"
        id="menu-button"
        aria-controls={openMenu ? 'side-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={openMenu ? 'true' : undefined}
        onClick={handleOpenMenu}
      />

      {/* Dropdown menu */}
      <Menu
        id="side-menu"
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleCloseMenu}
        MenuListProps={{
          'aria-labelledby': 'menu-button',
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {listMenu.map((item, i) => (
          <MenuItem key={i} onClick={() => handleCloseMenu(item.to)}>
            {item.text}
          </MenuItem>
        ))}
        {listCategory.map((item, i) => (
          <MenuItem key={i} onClick={() => handleCloseMenu(item.to)}>
            {`Category ${item.text}`}
          </MenuItem>
        ))}
      </Menu>
    </List>
  );

  return (
    <Drawer variant="permanent" open={sidebarOpen}>
      <DrawerHeader>
        <Box sx={{ display: 'flex', width: '100%', paddingLeft: 2 }}>
          <img src="/logo-santri.png" alt="santrihub" width={130} />
        </Box>
        <IconButton onClick={handleSidebarClose}>
          <ChevronLeftIcon />
        </IconButton>
      </DrawerHeader>
      <Divider />
      {list()}
    </Drawer>
  );
}
