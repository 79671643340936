import axios from '../../utils/axiosAuth'
import config from '../../utils/config';

const { URL_API } = config;
const ENDPOINT = 'santrihub_campuses';

export const getCampusesApi = async () => {
  try {
    const { data } = await axios({
      method: 'get',
      url: `${URL_API}/${ENDPOINT}`,
    });

    return data.result;
  } catch (error) {
    return error.response.data;
  }
};

export const addCampusApi = async (campus) => {
  try {
    const form = new FormData();

    for (const key in campus) {
      form.append(key, campus[key]);
    }


    const { data } = await axios({
      method: 'post',
      url: `${URL_API}/${ENDPOINT}`,
      data: form,
    });

    return data;
  } catch (error) {
    return error.response.data;
  }
};

export const getCampusByIdApi = async (id) => {
  try {
    const { data } = await axios({
      method: 'get',
      url: `${URL_API}/${ENDPOINT}/${id}`,
    });

    return data.result;
  } catch (error) {
    return error.response.data;
  }
};

export const editCampusApi = async ({ id, data: campus }) => {
  const form = new FormData();

  for (const key in campus) {
    form.append(key, campus[key]);
  }

  try {
    const { data } = await axios({
      method: 'put',
      url: `${URL_API}/${ENDPOINT}/${id}`,
      data: form,
    });

    return data;
  } catch (error) {
    return error.response.data;
  }
};

export const deleteCampusApi = async (id) => {
  try {
    const { data } = await axios({
      method: 'delete',
      url: `${URL_API}/${ENDPOINT}/${id}`,
    });

    return data;
  } catch (error) {
    return error.response.data;
  }
};
