import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import {
  deletePesantrenApi,
  getPesantrensApi,
} from '../../services/api/pesantrensApi';

export const getPesantrens = createAsyncThunk(
  '/pesantrens/getPesantrens',
  async () => {
    const response = await getPesantrensApi();
    return response.result;
  }
);
export const deletePesantren = createAsyncThunk(
  '/pesantrens/deletePesantren',
  async (id) => {
    await deletePesantrenApi(id);
    return id;
  }
);

const pesantrenEntity = createEntityAdapter({
  selectId: (pesantren) => pesantren.id,
});

export const pesantrenSlice = createSlice({
  name: 'pesantren',
  initialState: pesantrenEntity.getInitialState(),
  extraReducers: {
    [getPesantrens.fulfilled]: (state, action) => {
      pesantrenEntity.setAll(state, action.payload);
    },
    [deletePesantren.fulfilled]: (state, action) => {
      pesantrenEntity.removeOne(state, action.payload);
    },
  },
});

export const pesantrenSelector = pesantrenEntity.getSelectors(
  (state) => state.pesantren
);

export default pesantrenSlice.reducer;
