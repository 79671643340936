import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setItemId, setModalOpen } from '../redux/features/pageSlice';

export default function DetailCard({ fields, data }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleDelete = () => {
    dispatch(setModalOpen(true));
    dispatch(setItemId(data.id));
  };

  return data ? (
    <Card sx={{ mt: 2 }}>
      <CardContent>
        <Typography variant="h5" sx={{ mb: 2 }}>
          Detail
        </Typography>
        {data?.logo && (
          <img
            src={data.logo}
            alt={data?.name}
            style={{ maxWidth: '400px', marginBottom: 15 }}
          />
        )}
        <Grid container spacing={2}>
          {fields.map((f, i) => (
            <Fragment key={i}>
              <Grid xs={2}>
                <Typography>{f?.name}</Typography>
              </Grid>
              <Grid xs={10}>
                <Typography component="div">
                  : {f.formatter ? f.formatter(data) : data[f.field]}
                </Typography>
              </Grid>
            </Fragment>
          ))}
        </Grid>
      </CardContent>
      <CardActions sx={{ mb: 1 }}>
        <Button variant="contained" onClick={() => navigate(`${data.id}/edit`)}>
          Edit
        </Button>
        <Button variant="contained" color="error" onClick={handleDelete}>
          Delete
        </Button>
      </CardActions>
    </Card>
  ) : (
    <Card sx={{ mt: 2 }}>
      <CardContent>
        <Typography variant="h5" sx={{ mb: 2 }}>
          Detail
        </Typography>
        <Typography>Select a cell to view detail</Typography>
      </CardContent>
    </Card>
  );
}
