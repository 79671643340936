import { Box } from '@mui/material';
import NavBar from './NavBar';
import Navigation from './Navigation';

export default function Page({ children, items, title }) {
  return (
    <Box sx={{ display: 'flex' }}>
      <NavBar />
      <Box component="main" sx={{ flexGrow: 1, p: 3, paddingTop: 9, }}>
        <Navigation items={items} title={title} />
        {children}
      </Box>
    </Box>
  );
}
