import Joi from 'joi';

const campusSchema = Joi.object({
  name: Joi.string().required(),
  short_name: Joi.string().allow(''),
  telegram: Joi.string().allow(''),
  website: Joi.string().allow(''),
  description: Joi.string().allow(''),
  category_campus_id: Joi.number().integer().required(),
  province_id: Joi.number().integer().required(),
  city_id: Joi.number().integer().required(),
  image: Joi.object().unknown(true).required(),
  // image: Joi.string().allow(''),

});

const campusEditSchema = Joi.object({
  name: Joi.string().required(),
  short_name: Joi.string().allow(''),
  telegram: Joi.string().allow(''),
  website: Joi.string().allow(''),
  description: Joi.string().allow(''),
  category_campus_id: Joi.number().integer().required(),
  province_id: Joi.number().integer().required(),
  city_id: Joi.number().integer().required(),
}).unknown(true);

export { campusSchema, campusEditSchema };
