import {
  Autocomplete,
  Box,
  Button,
  TextField,
  Typography,
} from "@mui/material";
import Page from "../../components/Page";
import SaveIcon from "@mui/icons-material/Save";
import { useState } from "react";
import { useNavigate} from "react-router-dom";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';


import { jalurMasukSchema } from "../../schema/jalurMasuk";
import {
  addJalurMasukApi,
} from "../../services/api/jalurMasuksApi";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DatePicker } from '@mui/x-date-pickers';

import { getCampusesApi } from "../../services/api/campusesApi";
import {  useQuery, useQueryClient } from "@tanstack/react-query";
import mapOption from "../../utils/mapOption";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const configEditor = {
  toolbar: [
    "undo",
    "redo",
    "|",
    "heading",
    "|",
    "insertTable",
    "|",
    "fontfamily",
    "fontsize",
    "fontColor",
    "fontBackgroundColor",
    "|",
    "bold",
    "italic",
    "strikethrough",
    "subscript",
    "superscript",
    "code",
    "|",
    "link",
    "blockQuote",
    "codeBlock",
    "|",
    "bulletedList",
    "numberedList",
    "alignment",
    "todoList",
    "outdent",
    "indent",
  ],
  shouldNotGroupWhenFull: false,
};

export default function AddJalurMasuk() {
  const navigate = useNavigate();
  const queryKey = ["campus"];
  const [campuses, setCampuses] = useState({
    PTN: [],
    PTKIN: [],
    PTK: [],
    PTS: [],
    POLTEK: [],
  });

  const [dateTime, setDateTime] = useState({
    mulai_pendaftaran: null,
    akhir_pendaftaran: null,
    pengumuman: null,
  });

  // const [tahapan, setTahapan] = useState([""]);

  const [selectedCampuses, setSelectedCampuses] = useState();
  const [description, setDescription] = useState("");
  const [pendaftaran, setPandaftaran] = useState("");
  const [persyaratan, setPesyaratan] = useState("");
  const [timeline, setTimeline] = useState("");

  const [checkedState, setCheckedState] = useState(
    new Array([{label: 'Diploma', val: 'diploma'},{label: 'S1', val: 's1'}, {label: 'S2', val: 's2'}, {label: 'S3', val: 's3'}].length).fill(false)
  );

  const textFieldStyle = { mb: 2 };

  const { isSuccess } = useQuery(queryKey, () => getCampusesApi(), {
    onSuccess: (data) => {
      setCampuses({
        PTN: data
          .filter((c) => c.category_campus_name === "Perguruan Tinggi Negeri")
          .map(mapOption),
        PTKIN: data
          .filter((c) => c.category_campus_name === "Perguruan Tinggi Keagamaan Islam Negeri")
          .map(mapOption),
        PTK: data
          .filter((c) => c.category_campus_name === "Peguruan Tinggi Kedinasan")
          .map(mapOption),
        PTS: data
          .filter((c) => c.category_campus_name === "Perguruan Tinggi Swasta")
          .map(mapOption),
        POLTEK: data
          .filter((c) => c.category_campus_name === "Politeknik Negeri")
          .map(mapOption),
      });
    },
    refetchOnWindowFocus: false,
  });

  const campusesData = useQuery(["campuses"], getCampusesApi);

  // const handleAddTahapan = () => {
  //   setTahapan(tahapan.concat(''));
  // };
  // const handleDeleteTahapan = () => {
  //   setTahapan((t) => t.slice(0, -1));
  //   unregister(`tahapan[${tahapan.length - 1}]`);
  // };

  const handleChangeDateTime = (name, value) => {
    setDateTime({ ...dateTime, [name]: value });
    setValue(name, new Date(value));
  };

  const handleOnChange = (position) => {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    );
    setCheckedState(updatedCheckedState);
  }



  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm(
  //   {
  //   resolver: joiResolver(jalurMasukSchema),
  //   mode: "onBlur",
  // }
  );

  const handleChangeCampuses = (name, value) => {
    console.log({ name, value }, "name, value");
    if (value) {
      setSelectedCampuses((c) => ({ ...c, [name]: value }));
    }
  };

  const handleAddJalurMasuk = async (data) => {

    let santrihub_campus_id = [];
    for (const key in selectedCampuses) {
      santrihub_campus_id = santrihub_campus_id.concat(
        selectedCampuses[key].map((c) => c.id)
      );
    }

    data.description = description;
    data.pendaftaran = pendaftaran;
    data.persyaratan = persyaratan;
    data.timeline = timeline;
    data.santrihub_campus_id = santrihub_campus_id;
    data.diploma = checkedState[0]; 
    data.strata_1 = checkedState[1]; 
    data.strata_2 = checkedState[2];
    data.strata_3 = checkedState[3];


    const response = await addJalurMasukApi(data);
    //  console.log(response, 'response');
    if (response.success) navigate("/jalur-masuk");
  };

  return (
    <>
      <Page
        title="Add Jalur masuk"
        items={[
          { name: "Home", url: "/" },
          { name: "Jalur masuk", url: "/jalur-masuk" },
        ]}
      >
        {isSuccess && campusesData.isSuccess && (
          <Box
            component="form"
            sx={{ maxWidth: 500 }}
            onSubmit={handleSubmit(handleAddJalurMasuk)}
          >
            <TextField
              required
              fullWidth
              label="Name"
              sx={textFieldStyle}
              error={!!errors.name}
              helperText={errors?.name?.message}
              {...register("name")}
            />
            <TextField
              fullWidth
              label="Short name"
              sx={textFieldStyle}
              error={!!errors.short_name}
              helperText={errors?.short_name?.message}
              {...register("short_name")}
            />
            {/* <TextField
              fullWidth
              label="Strata"
              sx={textFieldStyle}
              error={!!errors.strata}
              helperText={errors?.strata?.message}
              {...register("strata")}
            />
            <TextField
              fullWidth
              label="Jenis seleksi"
              sx={textFieldStyle}
              error={!!errors.jenis_seleksi}
              helperText={errors?.jenis_seleksi?.message}
              {...register("jenis_seleksi")}
            /> */}
            {/* <TextField
              fullWidth
              label="Deskrpsi Singkat"
              sx={textFieldStyle}
              error={!!errors.description}
              helperText={errors?.description?.message}
              {...register("short_description")}
            /> */}
              <div>
              <Typography variant="h6" sx={{ mb: 1.5 }}>
           Jenjang
          </Typography>
           {[{label: 'Diploma', val: 'diploma'}, {label: 'S1', val: 's1'}, {label: 'S2', val: 's2'}, {label: 'S3', val: 's3'}].map((i, e) => {
            return (
              <FormControlLabel key={e}
              control={<Checkbox />}
              label={i.label}
              value={i.val}
              checked={checkedState[e]}
              onChange={() => handleOnChange(e)}
              labelPlacement="start"
              required={true}
            />
            )
           })}
           </div>
           <br/>
            {/* <Typography variant="h6" sx={{ mb: 1.5 }}>
              Tahapan
            </Typography>
            {tahapan.map((t, i) => (
              <Box
                sx={{ ml: 2, mb: 2, display: 'flex', alignItems: 'center' }}
                key={i}
              >
                <TextField
                  label={`Tahapan ${i + 1}`}
                  sx={{ flexGrow: 1 }}
                  {...register(`tahapan[${i}]`)}
                />
                {i + 1 === tahapan.length && (
                  <>
                    {i !== 0 && (
                      <Button
                        variant="contained"
                        size="small"
                        color="error"
                        sx={{ ml: 1 }}
                        onClick={handleDeleteTahapan}
                      >
                        Hapus
                      </Button>
                    )}
                    <Button
                      variant="contained"
                      size="small"
                      sx={{ ml: 1 }}
                      onClick={handleAddTahapan}
                    >
                      Tambah
                    </Button>
                  </>
                )}
              </Box>
            ))} */}
            <Box>
              <Typography variant="h6" sx={{ mb: 1.5 }}>
                Tahapan - Tahapan
              </Typography>
              <div style={{ width: "130%" }}>
                <div>
                  <h4>Deskripsi</h4>
                  <CKEditor
                    editor={ClassicEditor}
                    config={configEditor}
                    data={description}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setDescription(data);
                    }}
                  />
                </div>
              </div>
              <div style={{ width: "130%" }}>
                <div>
                  <h4>Persyaratan</h4>
                  <CKEditor
                    editor={ClassicEditor}
                    config={configEditor}
                    data={persyaratan}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setPesyaratan(data);
                    }}
                  />
                </div>
              </div>

              <div style={{ width: "130%" }}>
                <div>
                  <h4>Cara Pendaftaran</h4>
                  <CKEditor
                    editor={ClassicEditor}
                    config={configEditor}
                    data={pendaftaran}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setPandaftaran(data);
                    }}
                  />
                </div>
              </div>

              <div style={{ width: "130%", marginBottom: 40 }}>
                <div>
                  <h4>Timeline</h4>
                  <CKEditor
                    editor={ClassicEditor}
                    config={configEditor}
                    data={timeline}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setTimeline(data);
                    }}
                  />
                </div>
              </div>
            </Box>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Mulai pendaftaran"
                value={dateTime.mulai_pendaftaran}
                onChange={(value) =>
                  handleChangeDateTime("mulai_pendaftaran", value)
                }
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    required
                    sx={textFieldStyle}
                    {...params}
                  />
                )}
              />
              <DatePicker
                label="Akhir pendaftaran"
                value={dateTime.akhir_pendaftaran}
                onChange={(value) =>
                  handleChangeDateTime("akhir_pendaftaran", value)
                }
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    required
                    sx={textFieldStyle}
                    {...params}
                  />
                )}
              />
              {/* <DatePicker
                label="Pengumuman"
                value={dateTime.pengumuman}
                onChange={(value) => handleChangeDateTime("pengumuman", value)}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    required
                    sx={textFieldStyle}
                    {...params}
                  />
                )}
              /> */}
            </LocalizationProvider>
            <TextField
              fullWidth
              label="Website"
              sx={textFieldStyle}
              error={!!errors.website}
              helperText={errors?.website?.message}
              {...register("website")}
            />
            <TextField
              required
              fullWidth
              label="Biaya"
              type="number"
              sx={textFieldStyle}
              error={!!errors.biaya}
              helperText={errors?.biaya?.message}
              {...register("biaya")}
            />
            {/* Campuses */}
            <Typography variant="h6" sx={{ mb: 1.5 }}>
              Campuses
            </Typography>
            <CampusesField
              name="Perguruan Tinggi Negeri"
              campusesData={campusesData}
              campuses={campuses}
              textFieldStyle={textFieldStyle}
              handleChangeCampuses={handleChangeCampuses}
            />
            <CampusesField
              name="Perguruan Tinggi Keagamaan Islam Negeri"
              campusesData={campusesData}
              campuses={campuses}
              textFieldStyle={textFieldStyle}
              handleChangeCampuses={handleChangeCampuses}
            />
            <CampusesField
              name="Peguruan Tinggi Kedinasan"
              campusesData={campusesData}
              campuses={campuses}
              textFieldStyle={textFieldStyle}
              handleChangeCampuses={handleChangeCampuses}
            />
            <CampusesField
              name="Perguruan Tinggi Swasta"
              campusesData={campusesData}
              campuses={campuses}
              textFieldStyle={textFieldStyle}
              handleChangeCampuses={handleChangeCampuses}
            />
            <CampusesField
              name="Politeknik Negeri"
              campusesData={campusesData}
              campuses={campuses}
              textFieldStyle={textFieldStyle}
              handleChangeCampuses={handleChangeCampuses}
            />
            <Button type="submit" variant="contained" startIcon={<SaveIcon />}>
              Save
            </Button>
          </Box>
        )}
      </Page>
    </>
  );
}

function CampusesField({
  name,
  campusesData,
  campuses,
  textFieldStyle,
  handleChangeCampuses,
}) {
  return (
    <Autocomplete
      fullWidth
      multiple
      options={campusesData.data
        .filter((c) => c.category_campus_name === name)
        .map(mapOption)}
      sx={textFieldStyle}
      onChange={(_, value) => handleChangeCampuses(name, value)}
      // getOptionLabel={(option) => option.name }
      // to avoid warning "None of the options match with"
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderInput={(params) => <TextField label={name} {...params} />}
    />
  );
}
