import { Box, Link } from '@mui/material';
import Page from '../../components/Page';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import AddDeleteButton from '../../components/AddDeleteButton';
import ModalDelete from '../../components/ModalDelete';
import ActionField from '../../components/ActionField';
import { setSelection } from '../../redux/features/pageSlice';
import DetailCard from '../../components/DetailCard';
import {
  deleteOrganizationApi,
  getOrganizationsApi,
} from '../../services/api/organizationsApi';
import { useQuery } from '@tanstack/react-query';

export default function Organizations() {
  const dispatch = useDispatch();

  const queryKey = ['organizations'];
  const { data, isLoading } = useQuery(queryKey, getOrganizationsApi);

  const [pageSize, setPageSize] = useState(5);
  const [organization, setOrganization] = useState(null);

  const handleSelect = (selection) => {
    dispatch(setSelection(selection));
  };

  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    {
      field: 'name',
      headerName: 'Name',
      width: 150,
    },
    {
      field: 'short_name',
      headerName: 'Short name',
      width: 120,
    },
    {
      field: 'santrihub_campus',
      headerName: 'Campus',
      width: 80,
      valueFormatter: (params) => {
        return params.value.short_name;
      },
    },
    {
      field: 'category',
      headerName: 'Category',
      width: 130,
    },
    {
      field: 'ketua',
      headerName: 'Ketua',
      width: 120,
    },
    {
      field: 'alamat',
      headerName: 'Alamat',
      width: 140,
    },
    {
      field: 'description',
      headerName: 'Description',
      width: 150,
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 150,
    },
    {
      field: 'website',
      headerName: 'Website',
      width: 130,
      renderCell: (params) => {
        return (
          <Link href={params.value} target="_blank">
            {params.value}
          </Link>
        );
      },
    },
    {
      field: 'ig_link',
      headerName: 'Instagram',
      width: 130,
      renderCell: (params) => {
        return (
          <Link href={params.value} target="_blank">
            {params.value}
          </Link>
        );
      },
    },
    {
      field: 'no_contact',
      headerName: 'Contact',
      width: 150,
    },
    {
      field: 'createdAt',
      headerName: 'Created At',
      type: 'dateTime',
      width: 150,
      valueFormatter: (params) => {
        return new Date(params.value).toDateString();
      },
    },
    {
      field: 'action',
      headerName: 'Action',
      sortable: false,
      width: 160,
      renderCell: (params) => {
        return <ActionField id={params.id} />;
      },
    },
  ];

  const detailFields = [
    { field: 'id', name: 'ID' },
    {
      field: 'name',
      name: 'Name',
    },
    {
      field: 'short_name',
      name: 'Short name',
    },
    {
      field: 'santrihub_campus',
      name: 'Campus',
      formatter: (params) => {
        return params.santrihub_campus.name;
      },
    },
    {
      field: 'category',
      name: 'Category',
    },
    {
      field: 'ketua',
      name: 'Ketua',
    },
    {
      field: 'alamat',
      name: 'Alamat',
    },
    {
      field: 'description',
      name: 'Description',
    },
    {
      field: 'email',
      name: 'Email',
    },
    {
      field: 'website',
      name: 'Website',
      formatter: (params) => {
        return (
          <Link href={params.website} target="_blank">
            {params.website}
          </Link>
        );
      },
    },
    {
      field: 'ig_link',
      name: 'Instagram',
      formatter: (params) => {
        return (
          <Link href={params.ig_link} target="_blank">
            {params.ig_link}
          </Link>
        );
      },
    },
    {
      field: 'no_contact',
      name: 'Contact',
    },
    {
      field: 'createdAt',
      name: 'Created At',
      formatter: (params) => {
        return new Date(params.createdAt).toDateString();
      },
    },
    {
      field: 'updatedAt',
      name: 'Updated At',
      formatter: (params) => {
        return new Date(params.updatedAt).toDateString();
      },
    },
  ];

  return (
    <>
      <Page title="Organizations">
        <AddDeleteButton />
        <Box sx={{ height: 500, width: '100%' }}>
          <DataGrid
            components={{ Toolbar: GridToolbar }}
            rows={isLoading ? [] : data}
            loading={isLoading}
            columns={columns}
            pageSize={pageSize}
            rowsPerPageOptions={[5, 10, 15, 20]}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            checkboxSelection
            disableSelectionOnClick
            onSelectionModelChange={handleSelect}
            onCellClick={(params) => setOrganization(params.row)}
          />
        </Box>
        <ModalDelete handleDelete={deleteOrganizationApi} queryKey={queryKey} />
        <DetailCard fields={detailFields} data={organization} />
      </Page>
    </>
  );
}
