import axios from 'axios';
import config from '../../utils/config';

const { URL_API } = config;
const ENDPOINT = 'provinces';

export const getProvincesApi = async () => {
  try {
    const { data } = await axios({
      method: 'get',
      url: `${URL_API}/${ENDPOINT}`,
    });

    return data.data;
  } catch (error) {
    return error.response.data;
  }
};

export const getProvinceById = async (id) => {
  try {
    const { data } = await axios({
      method: 'get',
      url: `${URL_API}/${ENDPOINT}/${id}`,
    });

    return data.data;
  } catch (error) {
    return error.response.data;
  }
};
