/* eslint-disable array-callback-return */
import {
  Autocomplete,
  Box,
  Button,
  InputBase,
  TextField,
  Typography,
} from '@mui/material';
import Page from '../../components/Page';
import SaveIcon from '@mui/icons-material/Save';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { scholarshipSchema } from '../../schema/scholarship';
import { addScholarshipApi } from '../../services/api/scholarshipsApi';
import { DatePicker,  LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { getCampusesApi } from '../../services/api/campusesApi';
import { getCategoryScholarshipsApi } from '../../services/api/categoryScholarshipsApi';
import { grey } from '@mui/material/colors';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useMutation } from '@tanstack/react-query';

export default function AddScholarship() {
  const navigate = useNavigate();

  const textFieldStyle = { mb: 2 };
  const [logo, setLogo] = useState(null);

  const mutation = useMutation(addScholarshipApi, {
    onSuccess: () => navigate('/scholarships'),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm(
  //   {
  //   resolver: joiResolver(scholarshipSchema),
  //   mode: 'onBlur',
  // }
  );



  const [campuses, setCampuses] = useState([]);
  const [listCampus, setListCampus] = useState([]);

  const [categoryScholarships, setCategoryScholarships] = useState([]);
  const [dateTime, setDateTime] = useState({
    mulai_pendaftaran: null,
    akhir_pendaftaran: null,
    pengumuman: null,
    test_time: null
  });
  
  const [description, setDescription] = useState("");
  const [pendaftaran, setPandaftaran] = useState("");
  const [documents, setDocuments] = useState("");
  const [benefits, setBenefits] = useState("");
  const [persyaratan, setPesyaratan] = useState("");
  const [timeline, setTimeline] = useState('');

  const [checkedState, setCheckedState] = useState(
    new Array([{label: 'Diploma', val: 'diploma'},{label: 'S1', val: 's1'}, {label: 'S2', val: 's2'}, {label: 'S3', val: 's3'}].length).fill(false)
  );

  const handleOnChange = (position) => {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    );
    setCheckedState(updatedCheckedState);
  }

  const handleLogoInput = (e) => {
    e.preventDefault();
    setLogo(e.target.files[0]);
    setValue('image', e.target.files[0]);
  };

  const handleChangeDateTime = (name, value) => {
    setDateTime({ ...dateTime, [name]: value });
    setValue(name, new Date(value));
  };

  console.log({logo});


  const handleChangeCampuses = (e, value) => {
    if (value) {
      setListCampus(value.map((v) => v.id)
      );
    }
  };

  const handleChangeCategoryScholarship = (e, value) => {
    if (value) {
      setValue('category_scholarship_id', value.id);
    }
  };


  const handleAddScholarship = async (data) => {
    data.image = logo
    data.santrihub_campus_id = listCampus;
    data.description = description;
    data.cara_pendaftaran = pendaftaran;
    data.documents = documents;
    data.benefits = benefits;
    data.persyaratan = persyaratan;
    data.timeline = timeline;

    data.diploma = checkedState[0]; 
    data.strata_1 = checkedState[1]; 
    data.strata_2 = checkedState[2];
    data.strata_3 = checkedState[3];
    console.log({data}, 'data');

    mutation.mutate(data)
    // const response = await addScholarshipApi(data);
    // console.log({response}, 'response');
    
    // if (response.success) navigate('/scholarships');
  };


  const getData = useCallback(async () => {
    const responseCampuses = await getCampusesApi();
    const campusesMapped = responseCampuses.map((campus) => ({
      label: campus.name,
      id: campus.id,
    }));
    setCampuses(campusesMapped);

    const responseCategoryScholarships = await getCategoryScholarshipsApi();
    const categoryScholarshipsMapped = responseCategoryScholarships.map(
      (campus) => ({
        label: campus.name,
        id: campus.id,
      })
    );
    setCategoryScholarships(categoryScholarshipsMapped);
  }, []);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <>
      <Page
        title="Add Scholarship"
        items={[
          { name: "Home", url: "/" },
          { name: "Scholarships", url: "/scholarships" },
        ]}
      >
        <Box
          component="form"
          sx={{ maxWidth: 800 }}
          onSubmit={handleSubmit(handleAddScholarship)}
        >
          <TextField
            required
            fullWidth
            label="Name"
            sx={textFieldStyle}
            error={!!errors.name}
            helperText={errors?.name?.message}
            {...register("name")}
          />
          <TextField
            fullWidth
            label="Short name"
            sx={textFieldStyle}
            error={!!errors.short_name}
            helperText={errors?.short_name?.message}
            {...register("short_name")}
          />
          {/* <TextField
            fullWidth
            label="Strata"
            sx={textFieldStyle}
            error={!!errors.strata}
            helperText={errors?.strata?.message}
            {...register("strata")}
          /> */}
          <div>
            <p>Jenjang</p>
           {[{label: 'Diploma', val: 'diploma'},{label: 'S1', val: 's1'}, {label: 'S2', val: 's2'}, {label: 'S3', val: 's3'}].map((i, e) => {
            return (
              <FormControlLabel key={e}
              control={<Checkbox />}
              label={i.label}
              value={i.val}
              checked={checkedState[e]}
              onChange={() => handleOnChange(e)}
              labelPlacement="start"
              required={true}
            />
            )
           })}
          </div>
          <br />
          {/* <TextField
            fullWidth
            label="Jenis seleksi"
            sx={textFieldStyle}
            error={!!errors.jenis_seleksi}
            helperText={errors?.jenis_seleksi?.message}
            {...register("jenis_seleksi")}
          />
          <TextField
            fullWidth
            label="Jenis tanggungan"
            sx={textFieldStyle}
            error={!!errors.jenis_tanggungan}
            helperText={errors?.jenis_tanggungan?.message}
            {...register("jenis_tanggungan")}
          />
          <TextField
            fullWidth
            label="Besaran Beasiswa"
            sx={textFieldStyle}
            error={!!errors.nominal}
            helperText={errors?.nominal?.message}
            {...register("nominal")}
          />
          <TextField
            fullWidth
            label="Waktu Pemberian Beasiswa"
            sx={textFieldStyle}
            error={!!errors.per_time}
            helperText={errors?.per_time?.message}
            {...register("per_time")}
          />

          <TextField
            fullWidth
            label="Description"
            sx={textFieldStyle}
            error={!!errors.description}
            helperText={errors?.description?.message}
            {...register("description")}
          /> */}
          <TextField
            fullWidth
            label="Website"
            sx={textFieldStyle}
            error={!!errors.website}
            helperText={errors?.website?.message}
            {...register("website")}
          />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Mulai pendaftaran"
              value={dateTime.mulai_pendaftaran}
              onChange={(value) =>
                handleChangeDateTime("mulai_pendaftaran", value)
              }
              slotProps={{
                textField: {
                  helperText: 'MM/DD/YYYY',
                },
              }}
              renderInput={(params) => (
                <TextField fullWidth required sx={textFieldStyle} {...params} />
              )}
            />
            <DatePicker
              label="Akhir pendaftaran"
              value={dateTime.akhir_pendaftaran}
              onChange={(value) =>
                handleChangeDateTime("akhir_pendaftaran", value)
              }
              renderInput={(params) => (
                <TextField fullWidth required sx={textFieldStyle} {...params} />
              )}
            />
            {/* <DatePicker
              label="Tes Beasiswa"
              value={dateTime.test_time}
              onChange={(value) => handleChangeDateTime("test_time", value)}
              renderInput={(params) => (
                <TextField fullWidth required sx={textFieldStyle} {...params} />
              )}
            />
            <DatePicker
              label="Pengumuman"
              value={dateTime.pengumuman}
              onChange={(value) => handleChangeDateTime("pengumuman", value)}
              renderInput={(params) => (
                <TextField fullWidth required sx={textFieldStyle} {...params} />
              )}
            /> */}
          </LocalizationProvider>
          <Autocomplete
            fullWidth
            options={categoryScholarships}
            sx={textFieldStyle}
            onChange={handleChangeCategoryScholarship}
            renderInput={(params) => (
              <TextField required label="Category scholarship" {...params} />
            )}
          />
          <Autocomplete
            fullWidth
            multiple
            options={campuses}
            sx={textFieldStyle}
            onChange={handleChangeCampuses}
            renderInput={(params) => <TextField label="Campuses" {...params} />}
          />
          <label htmlFor="logo">
            <Box sx={{ display: 'flex' }}>
              <Typography
                sx={{
                  width: '50%',
                  padding: 1,
                  paddingLeft: 2,
                  border: `solid ${grey[400]}`,
                  borderWidth: '0.5px',
                  mr: 2,
                }}
              >
                Logo
              </Typography>
              <AddPhotoAlternateIcon fontSize="large" />
            </Box>
          </label>
          <InputBase
            fullWidth
            required
            id="logo"
            type="file"
            accept="image/png, image/jpeg, image/webp, image/gif"
            onChange={handleLogoInput}
            sx={{ ...textFieldStyle, mt: 1 }}
          />
          {logo && (
            <img
              src={URL.createObjectURL(logo)}
              alt=""
              style={{ width: '100%', marginBottom: 15 }}
              onLoad={(e) => URL.revokeObjectURL(e.target.src)}
            />
          )}
          {/* <TextField
            required
            fullWidth
            label="Logo"
            sx={textFieldStyle}
            error={!!errors.image}
            helperText={errors?.image?.message}
            {...register("image")}
          /> */}
          
          <div style={{ width: "100%%" }}>
          <div>
              <h4>Deskripsi</h4>
              <CKEditor
                editor={ClassicEditor}
                data={description}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setDescription(data);
                }}
              />
            </div>
            <div>
              <h4>Cara Pendaftaran</h4>
              <CKEditor
                editor={ClassicEditor}
                data={pendaftaran}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setPandaftaran(data);
                }}
              />
            </div>
            <div>
              <h4>Dokumen / Berkas</h4>
              <CKEditor
                editor={ClassicEditor}
                data={documents}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setDocuments(data);
                }}
              />
            </div>
            <div>
              <h4>Benefit / Keuntungan</h4>
              <CKEditor
                editor={ClassicEditor}
                data={benefits}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setBenefits(data);
                }}
              />
            </div>
            <div>
              <h4>Persyaratan</h4>
              <CKEditor
                editor={ClassicEditor}
                data={persyaratan}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setPesyaratan(data);
                }}
              />
            </div>
            <div>
              <h4>Timeline</h4>
              <CKEditor
                editor={ClassicEditor}
                data={timeline}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setTimeline(data);
                }}
              />
            </div>
          </div>
          <br />
          <Button
            className="mt-10"
            type="submit"
            variant="contained"
            startIcon={<SaveIcon />}
          >
            Save
          </Button>
          <br />
        </Box>
      </Page>
    </>
  );
}
