import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  modalOpen: false,
  selection: [],
  itemId: null,
};

export const pageSlice = createSlice({
  name: 'page',
  initialState,
  reducers: {
    setModalOpen: (state, action) => {
      state.modalOpen = action.payload;
    },
    setSelection: (state, action) => {
      state.selection = action.payload;
    },
    setItemId: (state, action) => {
      state.itemId = action.payload;
    },
  },
});

export const { setModalOpen, setSelection, setItemId } = pageSlice.actions;

export const pageSelector = (state) => state.page;

export default pageSlice.reducer;
