import Joi from 'joi';

const departmentSchema = Joi.object({
  name: Joi.string().required(),
  short_name: Joi.string().allow(''),
  website: Joi.string().allow(''),
  category_faculty_id: Joi.number().integer().required(),
});

export { departmentSchema };
