import { Box, Button, TextField } from '@mui/material';
import Page from '../../components/Page';
import SaveIcon from '@mui/icons-material/Save';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import categoryCampusSchema from '../../schema/categoryCampus';
import { addCategoryCampusApi } from '../../services/api/categoryCampusesApi';

export default function AddCategoryCampuse() {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: joiResolver(categoryCampusSchema),
  });

  const handleAddCategoryCampuse = async (data) => {
    await addCategoryCampusApi(data);
    navigate('/category-campuses');
  };

  return (
    <>
      <Page
        title="Add Category campuse"
        items={[
          { name: 'Home', url: '/' },
          { name: 'Category campuses', url: '/category-campuses' },
        ]}
      >
        <Box
          component="form"
          sx={{ maxWidth: 500 }}
          onSubmit={handleSubmit(handleAddCategoryCampuse)}
        >
          <TextField
            required
            fullWidth
            label="Name"
            sx={{ mb: 2 }}
            error={!!errors.name}
            helperText={errors?.name?.message}
            {...register('name')}
          />
          <TextField
            fullWidth
            label="Short name"
            sx={{ mb: 2 }}
            error={!!errors.short_name}
            helperText={errors?.short_name?.message}
            {...register('short_name')}
          />
          <TextField
            fullWidth
            label="Description"
            sx={{ mb: 2 }}
            error={!!errors.description}
            helperText={errors?.description?.message}
            {...register('description')}
          />
          <Button type="submit" variant="contained" startIcon={<SaveIcon />}>
            Save
          </Button>
        </Box>
      </Page>
    </>
  );
}
