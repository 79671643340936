import Joi from 'joi';

const pesantrenSchema = Joi.object({
  name: Joi.string().required(),
  short_name: Joi.string().allow(''),
  gender: Joi.string().required(),
  afiliate: Joi.string().allow(''),
  pengasuh: Joi.string().allow(''),
  description: Joi.string().allow(''),
  email: Joi.string().allow(''),
  website: Joi.string().allow(''),
  ig_link: Joi.string().allow(''),
  no_contact: Joi.string().allow(''),
  santrihub_campus_id: Joi.number().integer().required(),
  image: Joi.object().unknown(true).required(),
});

const pesantrenEditSchema = Joi.object({
  name: Joi.string().required(),
  short_name: Joi.string().allow(''),
  gender: Joi.string().required(),
  afiliate: Joi.string().allow(''),
  pengasuh: Joi.string().allow(''),
  description: Joi.string().allow(''),
  email: Joi.string().allow(''),
  website: Joi.string().allow(''),
  ig_link: Joi.string().allow(''),
  no_contact: Joi.string().allow(''),
  santrihub_campus_id: Joi.number().integer().required(),
  image: Joi.object().unknown(true),
});

export { pesantrenSchema, pesantrenEditSchema };
