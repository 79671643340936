import { Routes, Route } from 'react-router-dom';
import AddCampus from './pages/campuses/add';
import AddCategoryCampus from './pages/category-campuses/add';
import AddCategoryScholarship from './pages/category-scholarships/add';
import AddDepartment from './pages/departments/add';
import AddFaculty from './pages/faculties/add';
import AddJalurMasuk from './pages/jalur-masuk/add';
import AddRole from './pages/roles/add';
import AddScholarship from './pages/scholarships/add';
import AddUser from './pages/users/add';
import Campuses from './pages/campuses';
import CategoryCampuses from './pages/category-campuses';
import CategoryScholarships from './pages/category-scholarships';
import Departments from './pages/departments';
import EditCampus from './pages/campuses/edit';
import EditDepartment from './pages/departments/edit';
import EditFaculty from './pages/faculties/edit';
import EditJalurMasuk from './pages/jalur-masuk/edit';
import EditRole from './pages/roles/edit';
import EditScholarship from './pages/scholarships/edit';
import EditUser from './pages/users/edit';
import Faculties from './pages/faculties';
import Home from './pages/Home';
import JalurMasuks from './pages/jalur-masuk';
import Login from './pages/Login';
import Roles from './pages/roles';
import Scholarships from './pages/scholarships';
import Users from './pages/users';
import Organizations from './pages/organizations';
import AddOrganization from './pages/organizations/add';
import EditOrganization from './pages/organizations/edit';
import Pesantren from './pages/pesantren';
import AddPesantren from './pages/pesantren/add';
import EditPesantren from './pages/pesantren/edit';
import Signup from './pages/Signup';
import PrivateRoutes from './utils/PrivateRoutes';
import { getLocalUser, isTokenExist } from './services/token';
import SuperAdminOnlyRoutes from './utils/SuperAdminOnlyRoutes';

function App() {
  const isLogin = isTokenExist();
  const isSuperAdmin = isTokenExist() ? getLocalUser().role_id === 1 : false;

  return (
    <Routes>
      <Route path="/signup" element={<Signup />} />
      <Route path="/login" element={<Login />} />
      <Route element={<PrivateRoutes isLogin={isLogin} />}>
        <Route element={<SuperAdminOnlyRoutes isSuperAdmin={isSuperAdmin} />}>
          <Route path="/roles" element={<Roles />} />
          <Route path="/roles/add" element={<AddRole />} />
          <Route path="/roles/:id/edit" element={<EditRole />} />
          <Route path="/users" element={<Users />} />
          <Route path="/users/add" element={<AddUser />} />
          <Route path="/users/:id/edit" element={<EditUser />} />
        </Route>
        <Route path="/" element={<Home />} />
        <Route path="/category-campuses" element={<CategoryCampuses />} />
        <Route path="/category-campuses/add" element={<AddCategoryCampus />} />
        <Route path="/campuses" element={<Campuses />} />
        <Route path="/campuses/add" element={<AddCampus />} />
        <Route path="/campuses/:id/edit" element={<EditCampus />} />
        <Route path="/faculties" element={<Faculties />} />
        <Route path="/faculties/add" element={<AddFaculty />} />
        <Route path="/faculties/:id/edit" element={<EditFaculty />} />
        <Route path="/departments" element={<Departments />} />
        <Route path="/departments/add" element={<AddDepartment />} />
        <Route path="/departments/:id/edit" element={<EditDepartment />} />
        <Route path="/jalur-masuk" element={<JalurMasuks />} />
        <Route path="/jalur-masuk/add" element={<AddJalurMasuk />} />
        <Route path="/jalur-masuk/:id/edit" element={<EditJalurMasuk />} />
        <Route
          path="/category-scholarships"
          element={<CategoryScholarships />}
        />
        <Route
          path="/category-scholarships/add"
          element={<AddCategoryScholarship />}
        />
        <Route path="/scholarships" element={<Scholarships />} />
        <Route path="/scholarships/add" element={<AddScholarship />} />
        <Route path="/scholarships/:id/edit" element={<EditScholarship />} />
        <Route path="/organizations" element={<Organizations />} />
        <Route path="/organizations/add" element={<AddOrganization />} />
        <Route path="/organizations/:id/edit" element={<EditOrganization />} />
        <Route path="/pesantren" element={<Pesantren />} />
        <Route path="/pesantren/add" element={<AddPesantren />} />
        <Route path="/pesantren/:id/edit" element={<EditPesantren />} />
      </Route>
    </Routes>
  );
}

export default App;
