import { Box, Link } from '@mui/material';
import Page from '../../components/Page';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import AddDeleteButton from '../../components/AddDeleteButton';
import ModalDelete from '../../components/ModalDelete';
import ActionField from '../../components/ActionField';
import { setSelection } from '../../redux/features/pageSlice';
import DetailCard from '../../components/DetailCard';
import { useQuery } from '@tanstack/react-query';
import {
  deleteFacultyApi,
  getFacultiesApi,
} from '../../services/api/facultiesApi';

export default function Faculties() {
  const dispatch = useDispatch();
  const queryKey = ['faculties'];
  const { data, isLoading } = useQuery(queryKey, getFacultiesApi);

  const [pageSize, setPageSize] = useState(10);
  const [faculty, setFaculty] = useState(null);

  const handleSelect = (selection) => {
    dispatch(setSelection(selection));
  };

  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    {
      field: 'name',
      headerName: 'Name',
      width: 150,
    },
    {
      field: 'short_name',
      headerName: 'Short name',
      width: 120,
    },
    {
      field: 'campus',
      headerName: 'Campus',
      width: 150,
      valueFormatter: (params) => {
        return params.value.name;
      },
    },
    {
      field: 'website',
      headerName: 'Website',
      width: 130,
      renderCell: (params) => {
        return (
          <Link href={`https://${params.value}`} target="_blank">
            {params.value}
          </Link>
        );
      },
    },
    {
      field: 'description',
      headerName: 'Description',
      width: 150,
    },
    {
      field: 'createdAt',
      headerName: 'Created At',
      type: 'dateTime',
      width: 150,
      valueFormatter: (params) => {
        return new Date(params.value).toDateString();
      },
    },
    {
      field: 'action',
      headerName: 'Action',
      sortable: false,
      width: 160,
      renderCell: (params) => {
        return <ActionField id={params.id} />;
      },
    },
  ];

  const detailFields = [
    { field: 'id', name: 'ID' },
    {
      field: 'name',
      name: 'Name',
    },
    {
      field: 'short_name',
      name: 'Short name',
    },
    {
      field: 'campus',
      name: 'Campus',
      formatter: (data) => data.campus.name,
    },
    {
      field: 'website',
      name: 'Website',
      formatter: (data) => {
        return (
          <Link href={`https://${data.website}`} target="_blank">
            {data.website}
          </Link>
        );
      },
    },
    {
      field: 'description',
      name: 'Description',
    },
    {
      field: 'createdAt',
      name: 'Created At',
      formatter: (data) => {
        return new Date(data.createdAt).toDateString();
      },
    },
    {
      field: 'updatedAt',
      name: 'Updated At',
      formatter: (data) => {
        return new Date(data.updatedAt).toDateString();
      },
    },
  ];

  return (
    <>
      <Page title="Faculties">
        <AddDeleteButton />
        <Box sx={{ height: 500, width: '100%' }}>
          <DataGrid
            components={{ Toolbar: GridToolbar }}
            rows={isLoading ? [] : data}
            isLoading={isLoading}
            columns={columns}
            pageSize={pageSize}
            rowsPerPageOptions={[10, 20, 30, 40, 50]}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            checkboxSelection
            disableSelectionOnClick
            onSelectionModelChange={handleSelect}
            onCellClick={(params) => setFaculty(params.row)}
          />
        </Box>
        <ModalDelete handleDelete={deleteFacultyApi} queryKey={queryKey} />
        <DetailCard fields={detailFields} data={faculty} />
      </Page>
    </>
  );
}
