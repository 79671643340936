import axios from 'axios';
import config from '../../utils/config';
import { logoutLocal, setAccessToken, setLocalUser } from '../token';
import { decodeToken } from 'react-jwt';

const { URL_API } = config;
const ENDPOINT = 'auth';

export const loginApi = async (user) => {
  const { data } = await axios.post(`${URL_API}/${ENDPOINT}`, user, {
    withCredentials: true,
  });

  setAccessToken(data.data.accessToken);

  const decoded = decodeToken(data.data.accessToken);
  /**
   * 
 
  setLocalUser({
    id: decoded.id,
    email: decoded.email,
    role_id: decoded.role_id,
  });
    */
  setLocalUser({
    id: data.data.user.id,
    email: data.data.user.email,
    role_id: data.data.user.role_id,
  });

  return data;
};

export const logoutApi = async () => {
  // const { data } = await axios.delete(`${URL_API}/${ENDPOINT}`, {
  //   withCredentials: true,
  // });

  return logoutLocal();

  // return data;
};
