import axios from '../../utils/axiosAuth';
import config from '../../utils/config';

const { URL_API } = config;
const ENDPOINT = 'santrihub_users';

export const getUsersApi = async () => {
  try {
    const { data } = await axios({
      method: 'get',
      url: `${URL_API}/${ENDPOINT}`,
    });

    return data.data;
  } catch (error) {
    return error.response.data;
  }
};

export const addUserApi = async (user) => {
  const { data } = await axios({
    method: 'post',
    url: `${URL_API}/${ENDPOINT}`,
    data: user,
  });

  return data;
};

export const getUserByIdApi = async (id) => {
  try {
    const { data } = await axios({
      method: 'get',
      url: `${URL_API}/${ENDPOINT}/${id}`,
    });

    return data.data;
  } catch (error) {
    return error.response.data;
  }
};

export const editUserApi = async ({ id, data: userData }) => {
  try {
    const { data } = await axios({
      method: 'put',
      url: `${URL_API}/${ENDPOINT}/${id}`,
      data: userData,
    });

    return data;
  } catch (error) {
    return error.response.data;
  }
};

export const deleteUserApi = async (id) => {
  try {
    const { data } = await axios({
      method: 'delete',
      url: `${URL_API}/${ENDPOINT}/${id}`,
    });

    return data;
  } catch (error) {
    return error.response.data;
  }
};

export const registerApi = async (user) => {
  const { data } = await axios({
    method: 'post',
    url: `${URL_API}/${ENDPOINT}/contributor`,
    data: user,
  });

  return data;
};
