import axios from '../../utils/axiosAuth';
import config from '../../utils/config';

const { URL_API } = config;
const ENDPOINT = 'santrihub_scholarships';

export const getScholarshipsApi = async () => {
  try {
    const { data } = await axios({
      method: 'get',
      url: `${URL_API}/${ENDPOINT}`,
    });

    return data;
  } catch (error) {
    return error.response.data;
  }
};

export const addScholarshipApi = async (scholarshipData) => {
  try {
    const form = new FormData();

   if( scholarshipData?.santrihub_campus_id?.length > 0) {
    scholarshipData?.santrihub_campus_id.map((id, i) =>
    form.append(`santrihub_campus_id[${i}]`, id)
  );
  delete scholarshipData.santrihub_campus_id;

 
   }

   for (const key in scholarshipData) {
    form.append(key, scholarshipData[key]);
  }

    const { data } = await axios({
      method: 'post',
      url: `${URL_API}/${ENDPOINT}`,
      data: form,
    });

    return data;
  } catch (error) {
    console.log(error, 'gagal');

    return error.response.data;
  }
};

export const getScholarshipByIdApi = async (id) => {
  try {
    const { data } = await axios({
      method: 'get',
      url: `${URL_API}/${ENDPOINT}/${id}`,
    });

    return data;
  } catch (error) {
    return error.response.data;
  }
};

export const editScholarshipApi = async (payload) => {

  try {
    const scholarshipData = payload.data;
    const id = payload.id
    const form = new FormData();

    if( scholarshipData?.santrihub_campus_id && scholarshipData?.santrihub_campus_id.length > 0) {
      scholarshipData.santrihub_campus_id.forEach((id, i) =>
        form.append(`santrihub_campus_id[${i}]`, id)
      );
      delete scholarshipData.santrihub_campus_id;
  
    }
    for (const key in scholarshipData) {
      form.append(key, scholarshipData[key]);
    }

    const { data } = await axios({
      method: 'put',
      url: `${URL_API}/${ENDPOINT}/${id}`,
      data: form
    });

    return data;
  } catch (error) {
    console.log({error}, 'error editScholarshipApi ');
    return error.response.data;
  }
};

export const deleteScholarshipApi = async (id) => {
  try {
    const { data } = await axios({
      method: 'delete',
      url: `${URL_API}/${ENDPOINT}/${id}`,
    });

    return data;
  } catch (error) {
    return error.response.data;
  }
};
