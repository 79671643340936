import Joi from 'joi';

const facultySchema = Joi.object({
  name: Joi.string().required(),
  short_name: Joi.string().allow(''),
  website: Joi.string().allow(''),
  description: Joi.string().allow(''),
  santrihub_campus_id: Joi.number().integer().required(),
  // image: Joi.object().unknown(true).required(),
});

const facultyEditSchema = Joi.object({
  name: Joi.string().required(),
  short_name: Joi.string().allow(''),
  website: Joi.string().allow(''),
  description: Joi.string().allow(''),
  santrihub_campus_id: Joi.number().integer().required(),
}).unknown(true);

export { facultySchema, facultyEditSchema };
