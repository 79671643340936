import axios from '../../utils/axiosAuth';
import config from '../../utils/config';

const { URL_API } = config;
const ENDPOINT = 'santrihub_organizations';

export const getOrganizationsApi = async () => {
  try {
    const { data } = await axios({
      method: 'get',
      url: `${URL_API}/${ENDPOINT}`,
    });

    return data.result;
  } catch (error) {
    return error.response.data;
  }
};

export const addOrganizationApi = async (organizationData) => {
  try {
    const form = new FormData();

    for (const key in organizationData) {
      form.append(key, organizationData[key]);
    }

    const { data } = await axios({
      method: 'post',
      url: `${URL_API}/${ENDPOINT}`,
      data: form,
    });

    return data;
  } catch (error) {
    return error.response.data;
  }
};

export const getOrganizationByIdApi = async (id) => {
  try {
    const { data } = await axios({
      method: 'get',
      url: `${URL_API}/${ENDPOINT}/${id}`,
    });

    return data;
  } catch (error) {
    return error.response.data;
  }
};

export const editOrganizationApi = async (id, organizationData) => {
  try {
    const form = new FormData();

    for (const key in organizationData) {
      form.append(key, organizationData[key]);
    }

    const { data } = await axios({
      method: 'put',
      url: `${URL_API}/${ENDPOINT}/${id}`,
      data: form,
    });

    return data;
  } catch (error) {
    return error.response.data;
  }
};

export const deleteOrganizationApi = async (id) => {
  try {
    const { data } = await axios({
      method: 'delete',
      url: `${URL_API}/${ENDPOINT}/${id}`,
    });

    return data;
  } catch (error) {
    return error.response.data;
  }
};
