import axios from '../../utils/axiosAuth';
import config from '../../utils/config';

const { URL_API } = config;
const ENDPOINT = 'category_departements';

export const getDepartmentsApi = async () => {
  try {
    const { data } = await axios({
      method: 'get',
      url: `${URL_API}/${ENDPOINT}`,
    });

    return data.result;
  } catch (error) {
    return error.response.data;
  }
};

export const addDepartmentApi = async (departmentData) => {
  try {
    const { data } = await axios({
      method: 'post',
      url: `${URL_API}/${ENDPOINT}`,
      data: departmentData,
    });

    return data;
  } catch (error) {
    return error.response.data;
  }
};

export const getDepartmentByIdApi = async (id) => {
  try {
    const { data } = await axios({
      method: 'get',
      url: `${URL_API}/${ENDPOINT}/${id}`,
    });

    return data;
  } catch (error) {
    return error.response.data;
  }
};

export const editDepartmentApi = async (id, departmentData) => {
  try {
    const { data } = await axios({
      method: 'put',
      url: `${URL_API}/${ENDPOINT}/${id}`,
      data: departmentData,
    });

    return data;
  } catch (error) {
    return error.response.data;
  }
};

export const deleteDepartmentApi = async (id) => {
  try {
    const { data } = await axios({
      method: 'delete',
      url: `${URL_API}/${ENDPOINT}/${id}`,
    });

    return data;
  } catch (error) {
    return error.response.data;
  }
};
