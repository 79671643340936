import { Box, Link } from '@mui/material';
import Page from '../../components/Page';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import AddDeleteButton from '../../components/AddDeleteButton';
import ModalDelete from '../../components/ModalDelete';
import ActionField from '../../components/ActionField';
import { setSelection } from '../../redux/features/pageSlice';
import formatCurrency from '../../utils/formatCurrency';
import DetailCard from '../../components/DetailCard';
import { useQuery } from '@tanstack/react-query';
import {
  deleteJalurMasukApi,
  getJalurMasuksApi,
} from '../../services/api/jalurMasuksApi';

export default function JalurMasuks() {
  const dispatch = useDispatch();
  const queryKey = ['jalur-masuks'];
  const { data, isLoading } = useQuery(queryKey, getJalurMasuksApi);
  const [pageSize, setPageSize] = useState(10);
  const [jalurMasuk, setJalurMasuk] = useState(null);

  const handleSelect = (selection) => {
    dispatch(setSelection(selection));
  };

  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    {
      field: 'name',
      headerName: 'Name',
      width: 150,
    },
    {
      field: 'short_name',
      headerName: 'Short name',
      width: 120,
    },
    {
      field: 'strata',
      headerName: 'Strata',
      width: 70,
    },
    {
      field: 'jenis_seleksi',
      headerName: 'Jenis seleksi',
      width: 120,
    },
    {
      field: 'description',
      headerName: 'Description',
      width: 150,
    },
    // {
    //   field: 'tahapan',
    //   headerName: 'Tahapan',
    //   width: 150,
    //   valueFormatter: (params) => {
    //     return params.value.join(', ');
    //   },
    // },
    {
      field: 'mulai_pendaftaran',
      headerName: 'Mulai pendaftaran',
      type: 'dateTime',
      width: 150,
      valueFormatter: (params) => {
        return new Date(params.value).toDateString();
      },
    },
    {
      field: 'akhir_pendaftaran',
      headerName: 'Akhir pendaftaran',
      type: 'dateTime',
      width: 150,
      valueFormatter: (params) => {
        return new Date(params.value).toDateString();
      },
    },
    {
      field: 'pengumuman',
      headerName: 'Pengumuman',
      type: 'dateTime',
      width: 150,
      valueFormatter: (params) => {
        return new Date(params.value).toDateString();
      },
    },
    {
      field: 'biaya',
      headerName: 'Biaya',
      type: 'number',
      width: 120,
      valueFormatter: (params) => {
        return `Rp${formatCurrency(params.value)}`;
      },
    },
    {
      field: 'website',
      headerName: 'Website',
      width: 130,
      renderCell: (params) => {
        return (
          <Link href={`https://${params.value}`} target="_blank">
            {params.value}
          </Link>
        );
      },
    },
    {
      field: 'createdAt',
      headerName: 'Created At',
      type: 'dateTime',
      width: 150,
      valueFormatter: (params) => {
        return new Date(params.value).toDateString();
      },
    },
    {
      field: 'action',
      headerName: 'Action',
      sortable: false,
      width: 160,
      renderCell: (params) => {
        return <ActionField id={params.id} />;
      },
    },
  ];

  const detailFields = [
    { field: 'id', name: 'ID' },
    {
      field: 'name',
      name: 'Name',
    },
    {
      field: 'short_name',
      name: 'Short name',
    },
    {
      field: 'strata',
      name: 'Strata',
    },
    {
      field: 'campus',
      name: 'Campus',
      formatter: (params) => {
        return params?.campuses?.map(i => i?.short_name).join(', ') ?? '' ;
      },
    },
    {
      field: 'jenis_seleksi',
      name: 'Jenis seleksi',
    },
    {
      field: 'description',
      name: 'Description',
    },
    // {
    //   field: 'tahapan',
    //   name: 'Tahapan',
    //   formatter: (params) => (
    //     <ol style={{ margin: 0 }}>
    //       {params.tahapan.map((t, i) => (
    //         <li key={i}>{t}</li>
    //       ))}
    //     </ol>
    //   ),
    // },
    {
      field: 'mulai_pendaftaran',
      name: 'Mulai pendaftaran',
      formatter: (params) => {
        return new Date(params.mulai_pendaftaran).toDateString();
      },
    },
    {
      field: 'akhir_pendaftaran',
      name: 'Akhir pendaftaran',
      formatter: (params) => {
        return new Date(params.akhir_pendaftaran).toDateString();
      },
    },
    {
      field: 'pengumuman',
      name: 'Pengumuman',
      formatter: (params) => {
        return new Date(params.pengumuman).toDateString();
      },
    },
    {
      field: 'biaya',
      name: 'Biaya',
      formatter: (params) => {
        return `Rp${formatCurrency(params.biaya)}`;
      },
    },
    {
      field: 'website',
      name: 'Website',
      formatter: (params) => {
        return (
          <Link href={`https://${params.website}`} target="_blank">
            {params.website}
          </Link>
        );
      },
    },
    {
      field: 'createdAt',
      name: 'Created At',
      formatter: (params) => {
        return new Date(params.createdAt).toDateString();
      },
    },
    {
      field: 'updatedAt',
      name: 'Updated At',
      formatter: (params) => {
        return new Date(params.updatedAt).toDateString();
      },
    },
  ];

  console.log(data, 'jalurMasuk');
  return (
    <>
      <Page title="Jalur Masuk">
        <AddDeleteButton />
        <Box sx={{ height: 500, width: '100%' }}>
          <DataGrid
            components={{ Toolbar: GridToolbar }}
            rows={isLoading ? [] : data}
            loading={isLoading}
            columns={columns}
            pageSize={pageSize}
            rowsPerPageOptions={[10, 20, 30, 40, 50]}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            checkboxSelection
            disableSelectionOnClick
            onSelectionModelChange={handleSelect}
            onCellClick={(params) => setJalurMasuk(params.row)}
          />
        </Box>
        <ModalDelete handleDelete={deleteJalurMasukApi} queryKey={queryKey} />
        <DetailCard fields={detailFields} data={jalurMasuk} />
      </Page>
    </>
  );
}
