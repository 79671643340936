export const getAccessToken = () => {
  const token = localStorage.getItem('accessToken');
  return token;
};

export const setAccessToken = (token) => {
  localStorage.setItem('accessToken', token);
};

export const isTokenExist = () => {
  const accessToken = localStorage.getItem('accessToken');

  if (!accessToken) return false;

  return true;
};

export const setLocalUser = (user) => {
  localStorage.setItem('user', JSON.stringify(user));
};

export const getLocalUser = () => {
  return JSON.parse(localStorage.getItem('user'));
};

export const logoutLocal = () => {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('user');
};
