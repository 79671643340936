import { Autocomplete, Box, Button, TextField } from '@mui/material';
import Page from '../../components/Page';
import SaveIcon from '@mui/icons-material/Save';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { departmentSchema } from '../../schema/department';
import {
  editDepartmentApi,
  getDepartmentByIdApi,
} from '../../services/api/departmentsApi';
import { getFacultiesApi } from '../../services/api/facultiesApi';

export default function EditDepartment() {
  const { id } = useParams();
  const navigate = useNavigate();

  const textFieldStyle = { mb: 2 };

  const [faculties, setFaculties] = useState([]);
  const [faculty, setFaculty] = useState(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: joiResolver(departmentSchema),
    mode: 'onBlur',
  });

  const handleChangeFaculty = (e, value) => {
    if (value) {
      setFaculty(value);
      setValue('category_faculty_id', value.id);
    }
  };

  const handleEditDepartment = async (data) => {
    await editDepartmentApi(id, data);
    navigate('/departments');
  };

  const getData = useCallback(async () => {
    const responseDepartment = await getDepartmentByIdApi(id);
    const { name, short_name, website, category_faculty_id, faculty } =
      responseDepartment.result;

    setFaculty({
      label: faculty.name,
      id: category_faculty_id,
    });

    const department = {
      name,
      short_name,
      website,
      category_faculty_id,
    };

    for (const key in department) {
      setValue(key, department[key]);
    }

    const responseFaculties = await getFacultiesApi();
    const facultiesMapped = responseFaculties.map((faculty) => ({
      label: faculty.name,
      id: faculty.id,
    }));
    setFaculties(facultiesMapped);
  }, [id, setValue]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <>
      <Page
        title="Edit Department"
        items={[
          { name: 'Home', url: '/' },
          { name: 'Departments', url: '/departments' },
        ]}
      >
        <Box
          component="form"
          sx={{ maxWidth: 500 }}
          onSubmit={handleSubmit(handleEditDepartment)}
        >
          <TextField
            required
            fullWidth
            label="Name"
            sx={textFieldStyle}
            error={!!errors.name}
            helperText={errors?.name?.message}
            {...register('name')}
          />
          <TextField
            fullWidth
            label="Short name"
            sx={textFieldStyle}
            error={!!errors.short_name}
            helperText={errors?.short_name?.message}
            {...register('short_name')}
          />
          <TextField
            fullWidth
            label="Website"
            sx={textFieldStyle}
            error={!!errors.website}
            helperText={errors?.website?.message}
            {...register('website')}
          />
          <Autocomplete
            fullWidth
            options={faculties}
            sx={textFieldStyle}
            onChange={handleChangeFaculty}
            // to avoid warning "None of the options match with"
            isOptionEqualToValue={(option, value) => option.id === value.id}
            value={faculty}
            renderInput={(params) => (
              <TextField required label="Faculty" {...params} />
            )}
          />
          <Button type="submit" variant="contained" startIcon={<SaveIcon />}>
            Save
          </Button>
        </Box>
      </Page>
    </>
  );
}
